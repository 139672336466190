import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "@tanstack/react-router";
import {
  Button,
  ConfigProvider,
  message,
  Popconfirm,
  PopconfirmProps,
  Space,
  Typography,
} from "antd";

import {
  useCancelAppointmentMutation,
  useDeleteTimetableMutation,
} from "../generated/graphql.tsx";

export interface ISplitButtonProperties {
  isDisabled?: boolean;
  id: number;
  title: string;
  onClick: () => Promise<void>;
  isAppointment: boolean;
}

const { Title } = Typography;

const cancel: PopconfirmProps["onCancel"] = (event): void => {
  console.log(event);
};

export const SplitButton = (
  properties: ISplitButtonProperties,
): ReactElement => {
  const { t } = useTranslation([
    "appointment",
    "timetable",
    "common",
    "view",
    "notification",
  ]);
  const navigate = useNavigate();
  const [cancelAppointment] = useCancelAppointmentMutation();
  const [deleteTimetable] = useDeleteTimetableMutation();

  const handleDelete = async (): Promise<void> => {
    if (properties.isAppointment) {
      const response = await cancelAppointment({
        variables: {
          appointmentId: properties.id,
        },
      });
      if (response.data?.cancelAppointment) {
        message.info(t("appointment:appointment_deleted_success"));
        await navigate({
          to: "/",
        });
      } else {
        message.error(t("appointment:appointment_deleted_error"));
      }
    } else {
      const response = await deleteTimetable({
        variables: {
          timetableId: properties.id,
        },
      });
      if (response.data?.deleteTimetable) {
        message.info(t("timetable:timetable_deleted_success"));
        await navigate({
          to: "/",
        });
      } else {
        message.error(t("timetable:timetable_deleted_error"));
      }
    }
  };

  return (
    <Space.Compact
      style={{
        width: "100%",
      }}
      size={"large"}
    >
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#5B17EA",
          },
        }}
      >
        <Button
          disabled={properties.isDisabled || false}
          style={{
            width: "100%",
            borderTopLeftRadius: "15px",
            borderBottomLeftRadius: "15px",
            borderTopRightRadius: "5px",
            borderBottomRightRadius: "5px",
            backgroundColor: "#5B17EA",
            color: "white",
          }}
          type={"text"}
          onClick={properties.onClick}
        >
          <Title
            level={5}
            style={{
              color: "white",
              margin: "0",
            }}
          >
            {properties.title}
          </Title>
        </Button>
      </ConfigProvider>
      <Popconfirm
        title={
          properties.isAppointment
            ? t("appointment:appointment_delete_action")
            : t("timetable:timetable_delete_action")
        }
        description={
          properties.isAppointment
            ? t("appointment:appointment_delete_question")
            : t("timetable:timetable_delete_question")
        }
        onConfirm={handleDelete}
        onCancel={cancel}
        okText={t("common:yes")}
        cancelText={t("common:no")}
      >
        <Button
          style={{
            borderTopRightRadius: "15px",
            borderBottomRightRadius: "15px",
            borderTopLeftRadius: "5px",
            borderBottomLeftRadius: "5px",
            marginLeft: "10px",
          }}
          shape="circle"
          danger
          type={"primary"}
          icon={<DeleteOutlined />}
        />
      </Popconfirm>
    </Space.Compact>
  );
};
