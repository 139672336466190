// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable unicorn/no-null */
import { JSX, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserOutlined } from "@ant-design/icons";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import {
  Avatar,
  DatePicker,
  Flex,
  Input,
  message,
  TimePicker,
  Typography,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import "dayjs/locale/zh-cn";

import { SplitButton } from "../../components/split-button.tsx";
import {
  useGetAppointmentQuery,
  useUpdateAppointmentMutation,
} from "../../generated/graphql.tsx";
import { useAuthStore } from "../../stores/auth.store.ts";

dayjs.extend(utc);
dayjs.extend(timezone);

type EditParameters = {
  appointmentId: string;
};

export const Route = createFileRoute("/appointment/edit")({
  component: AppointmentEdit,
  validateSearch: (parameters: EditParameters): EditParameters => {
    if (!parameters.appointmentId) {
      throw new Error("Appointment ID is missing");
    }
    return parameters;
  },
});

const { Title, Text } = Typography;
const { TextArea } = Input;

function AppointmentEdit(): JSX.Element {
  const { t } = useTranslation(["appointment"]);
  const navigate = useNavigate();
  const parameters = Route.useSearch();
  const appointmentId: string = parameters.appointmentId;
  const [update] = useUpdateAppointmentMutation();
  const authStore = useAuthStore();
  console.log("authStore:", authStore);

  useEffect(() => {
    console.log("authStore.token:", authStore.token);
    if (!authStore.token || !authStore.account?.id) {
      navigate({
        to: "/login",
      });
    }
  }, [authStore]);

  const {
    data: getAppointmentData,
    loading: getAppointmentLoading,
    error: getAppointmentError,
  } = useGetAppointmentQuery({
    variables: { appointmentId: Number.parseInt(appointmentId) },
    skip: !appointmentId,
  });

  const [date, setDate] = useState<Dayjs | null>(null);
  const [startTime, setStartTime] = useState<Dayjs | null>(null);
  const [endTime, setEndTime] = useState<Dayjs | null>(null);

  const [comment, setComment] = useState<string>("");

  useEffect(() => {
    if (getAppointmentData) {
      const startAt = dayjs(
        getAppointmentData.getAppointment.timeslot.startAt,
      ).tz(dayjs.tz.guess());
      const endAt = dayjs(getAppointmentData.getAppointment.timeslot.endAt).tz(
        dayjs.tz.guess(),
      );

      setDate(startAt);
      setStartTime(startAt);
      setEndTime(endAt);
      setComment(getAppointmentData.getAppointment.comment || "");
    } else {
      const now = dayjs().tz(dayjs.tz.guess());
      setDate(now);
      setStartTime(now);
      setEndTime(now.add(1, "hour"));
      setComment(t("appointment:comment_placeholder"));
    }
  }, [getAppointmentData]);

  const handleUpdateAppointment = async (): Promise<void> => {
    try {
      const startAt =
        date && startTime
          ? date.hour(startTime.hour()).minute(startTime.minute()).second(0)
          : null;
      const endAt =
        date && endTime
          ? date.hour(endTime.hour()).minute(endTime.minute()).second(0)
          : null;

      const updateAppointmentResponse = await update({
        variables: {
          updateAppointmentInput: {
            appointmentId: Number.parseInt(appointmentId),
            comment: comment,
            startTime: startAt?.toISOString(),
            endTime: endAt?.toISOString(),
          },
        },
      });

      if (updateAppointmentResponse.data?.updateAppointment) {
        message.success(t("appointment:appointment_updated_success"));
      }
    } catch {
      message.error(t("appointment:appointment_updated_error"));
    }
  };

  if (getAppointmentLoading) {
    return <div>Loading...</div>;
  }

  if (getAppointmentError) {
    return <div>Error: {getAppointmentError.message}</div>;
  }

  const onDateChange = (dateValue: Dayjs | null): void => {
    setDate(dateValue);
  };

  const onTimeChange = (timeValue: Dayjs | null, timeType: string): void => {
    if (timeType === "start") {
      setStartTime(timeValue);
    } else {
      setEndTime(timeValue);
    }
  };

  const format = "HH:mm";

  const firstLetter = authStore!
    .account!.profile!.name!.charAt(0)
    .toUpperCase();

  return (
    <Flex vertical gap={"middle"}>
      <Flex vertical align={"center"} gap={"middle"}>
        <Flex vertical align={"center"}>
          <Title level={5}>{t("appointment:appointment_edit_title")}</Title>
          <Text type="secondary" style={{ textAlign: "center" }}>
            {t("appointment:appointment_edit_description")}
          </Text>
        </Flex>
        <Avatar
          style={{
            backgroundColor: "none",
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url('data:image/svg+xml;base64,${authStore!.account!.profile!.avatarUrl}')`,
            borderRadius: "15px",
          }}
          shape="square"
          size={100}
          icon={
            authStore?.account?.profile?.avatarUrl ? undefined : (
              <UserOutlined />
            )
          }
        >
          <span
            style={{
              position: "absolute",
              zIndex: "2",
              color: "white",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {firstLetter}
          </span>
        </Avatar>
        <Input
          style={{ borderRadius: "15px" }}
          placeholder="Filled"
          variant="filled"
          disabled={true}
          value={getAppointmentData?.getAppointment?.client?.name || "No name"}
        />
        <TextArea
          style={{ borderRadius: "15px" }}
          placeholder={t("appointment:comment_placeholder")}
          value={comment}
          onChange={(event) => setComment(event.target.value)}
          variant="filled"
          autoSize={{ minRows: 5, maxRows: 9 }}
          maxLength={2000}
        />
        <DatePicker
          variant={"filled"}
          style={{ width: "100%", borderRadius: "15px" }}
          value={date}
          onChange={onDateChange}
        />
        <TimePicker.RangePicker
          style={{ width: "100%", borderRadius: "15px" }}
          format={format}
          variant={"filled"}
          value={[startTime, endTime]}
          onChange={(values) => {
            if (values) {
              onTimeChange(values[0], "start");
              onTimeChange(values[1], "end");
            }
          }}
        />
        <SplitButton
          isDisabled={!date || !startTime || !endTime}
          isAppointment={true}
          id={Number.parseInt(appointmentId)}
          title={t("appointment:appointment_update_action")}
          onClick={handleUpdateAppointment}
        />
      </Flex>
    </Flex>
  );
}
