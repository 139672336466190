import { create } from "zustand";
import { devtools } from "zustand/middleware";

export interface IPreviewTimeslot {
  id: number;
  startTime: Date;
  endTime: Date;
  ownerId: number;
}

export interface IPreviewTimetable {
  title: string;
  description: string;
  ownerId: number;
  timeslots: IPreviewTimeslot[];
}

type ITimetableState = {
  timetable: IPreviewTimetable | undefined;
  setTimetable: (timetable: IPreviewTimetable) => void;
  clear: () => void;
};

export const useDataStore = create(
  devtools<ITimetableState>((set) => ({
    timetable: undefined as IPreviewTimetable | undefined,
    setTimetable: (timetable: IPreviewTimetable): void => {
      set({ timetable });
    },
    clear: (): void => {
      set({ timetable: undefined });
    },
  })),
);
