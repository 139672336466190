import { createStyles } from "antd-style";

export const useCalendarStyles = createStyles(({ token, css }) => {
  return {
    dateCell: css`
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 30px;
        height: 30px;
        background: transparent;
        transition: background 300ms;
        border-radius: 10px;
        border: 1px solid transparent;
        box-sizing: border-box;
      }
      &:hover:before {
        background: rgba(0, 0, 0, 0.04);
      }
    `,
    today: css`
      margin: auto;
      width: 30px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;

      &:before {
        border: 2px solid #5b17ea;
        background: rgba(91, 23, 234, 0.07);
      }
    `,
    text: css`
      position: relative;
      z-index: 1;
      color: ${token.colorText};
    `,
    weekend: css`
      color: ${token.colorError};
      &.gray {
        opacity: 0.4;
      }
    `,
    current: css`
      margin: auto;
      width: 30px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      color: ${token.colorTextLightSolid};
      background: #5b17ea;

      &:hover {
        opacity: 0.8;
      }
    `,
    hasTimeslot: css`
      margin: auto;
      width: 30px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      background: ${token.colorPrimary};
      color: ${token.colorTextLightSolid};
    `,
  };
});
