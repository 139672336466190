// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable unicorn/no-null */
import { JSX, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import {
  Avatar,
  Button,
  Calendar,
  CalendarProps,
  Col,
  ConfigProvider,
  Drawer,
  Flex,
  Input,
  message,
  Modal,
  Row,
  Tag,
  Typography,
} from "antd";
import { useTheme } from "antd-style";
import { enUS, ru } from "date-fns/locale";
import { format } from "date-fns-tz";
import dayjs, { Dayjs } from "dayjs";

import "dayjs/locale/en";
import "dayjs/locale/ru";

import partyFace from "../../assets/party-face.svg";
import smilingFace from "../../assets/smiling-face.svg";
import { useCalendarStyles } from "../../common/calendar-styles.ts";
import { useDrawerStyles } from "../../common/drawer-styles.ts";
import { MainButton } from "../../components/main-button.tsx";
import { ITimeslotProperties, Timeslot } from "../../components/timeslot.tsx";
import {
  AppointmentRole,
  useAddNotificationJobMutation,
  useBookAppointmentMutation,
  useDeleteTimeslotMutation,
  useGetTimetableQuery,
} from "../../generated/graphql.tsx";
import { useAuthStore } from "../../stores/auth.store.ts";

const { CheckableTag } = Tag;
const { Text, Title } = Typography;
const { TextArea } = Input;

export interface Timeslot {
  id: number;
  startAt: Date;
  endAt: Date;
  createdAt: Date;
  updatedAt: Date;
  ownerId: number;
}

type ViewParameters = {
  ownerId: string;
};

enum Category {
  Morning = "morning",
  Afternoon = "afternoon",
  Evening = "evening",
}

export const Route = createFileRoute("/timetable/view")({
  component: TimetableView,
  validateSearch: (parameters: ViewParameters): ViewParameters => {
    if (!parameters.ownerId) {
      throw new Error("Account ID is missing");
    }
    return parameters;
  },
});

export interface IGroupedTimeslots {
  morning: Timeslot[];
  afternoon: Timeslot[];
  evening: Timeslot[];
}

const onPanelChange = (
  value: Dayjs,
  mode: CalendarProps<Dayjs>["mode"],
): void => {
  console.log(value.format("YYYY-MM-DD"), mode);
};

const categorizeTimeslot = (time: Date | string): Category => {
  const date = typeof time === "string" ? new Date(time) : time;
  const hour = date.getHours();

  if (hour >= 6 && hour < 12) return Category.Morning;
  if (hour >= 12 && hour < 18) return Category.Afternoon;
  return Category.Evening;
};

function TimetableView(): JSX.Element {
  const { t, i18n } = useTranslation([
    "appointment",
    "timetable",
    "common",
    "view",
    "notification",
  ]);
  const locale = i18n.language === "ru" ? ru : enUS;
  const navigate = useNavigate();
  const parameters = Route.useSearch();
  const ownerId: string = parameters.ownerId;
  const { styles: drawerStyles } = useDrawerStyles();
  const { styles: calendarStyles } = useCalendarStyles();
  const token = useTheme();
  const [addNotificationJob] = useAddNotificationJobMutation();
  const [bookAppointment] = useBookAppointmentMutation();
  const [bookedTimeslots, setBookedTimeslots] = useState<number[]>([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [timeslots, setTimeslots] = useState<Timeslot[]>([]);
  const [selectedTag, setSelectedTag] = useState<number | null>(null);
  const [deleteTimeslot] = useDeleteTimeslotMutation();
  const [appointmentId, setAppointmentId] = useState<number | null>(null);
  const [comment, setComment] = useState<string | undefined>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNotifications, setSelectedNotifications] = useState<number[]>([
    0, 15,
  ]);

  const formatMeetingTime = (startAt: Date): string => {
    return format(startAt, "eee, d MMMM - HH:mm", { locale });
  };

  const authStore = useAuthStore();

  useEffect(() => {
    if (!authStore.token || !authStore.account?.id) {
      navigate({
        to: "/login",
        search: {
          viewOwnerId: ownerId,
        },
      });
    }
  }, [authStore]);

  useEffect(() => {
    if (authStore.account?.profile?.id === Number.parseInt(ownerId)) {
      setIsAdmin(true);
    } else {
      setIsClient(true);
    }
  }, [authStore.account?.profile?.id, ownerId]);

  const {
    data: getTimetable,
    loading: getTimetableLoading,
    error: getTimetableError,
  } = useGetTimetableQuery({
    variables: { ownerId: Number.parseInt(ownerId) },
    skip: !ownerId,
  });

  const groupTimeslotsByCategory = (
    timeslotsToGroup: Timeslot[],
  ): IGroupedTimeslots => {
    const grouped: IGroupedTimeslots = {
      morning: [],
      afternoon: [],
      evening: [],
    };

    for (const timeslot of timeslotsToGroup) {
      if (!bookedTimeslots.includes(timeslot.id)) {
        const category = categorizeTimeslot(timeslot.startAt);
        grouped[category].push(timeslot);
      }
    }

    return grouped;
  };

  const handleNotificationTagClick = (value: number): void => {
    if (selectedNotifications.includes(value)) {
      setSelectedNotifications(
        selectedNotifications.filter((notification) => notification !== value),
      );
    } else {
      setSelectedNotifications([...selectedNotifications, value]);
    }
  };

  const calculateNotificationTime = (
    startAt: Date,
    notification: number,
  ): Date => {
    const date = new Date(startAt);
    switch (notification) {
      case 0: {
        date.setMinutes(date.getMinutes());
        break;
      }
      case 15: {
        date.setMinutes(date.getMinutes() - 15);
        break;
      }
      case 30: {
        date.setMinutes(date.getMinutes() - 30);
        break;
      }
      case 60: {
        date.setHours(date.getHours() - 1);
        break;
      }
      case 60 * 24: {
        date.setDate(date.getDate() - 1);
        break;
      }
      case 60 * 24 * 2: {
        console.log(date);
        date.setDate(date.getDate() - 2);
        break;
      }
      default: {
        break;
      }
    }
    return date;
  };

  const handleAddNotificationJob = async (): Promise<void> => {
    const selectedTimeslot = timeslots.find(
      (timeslot) => timeslot.id === selectedTag,
    );

    if (!selectedTimeslot?.startAt) {
      message.error(t("notification:invalid_timeslot"));
      return;
    }

    const notifications = selectedNotifications.map((notification) => ({
      sendNotificationAt: calculateNotificationTime(
        selectedTimeslot.startAt as Date,
        notification,
      ),
    }));

    const response = await addNotificationJob({
      variables: {
        addNotificationsJobInput: {
          appointmentId: appointmentId!,
          role: AppointmentRole.Client,
          startTime: new Date(selectedTimeslot.startAt as Date), // Оригинальное время старта
          notifications: notifications,
        },
      },
    });

    if (response.data?.addNotificationsToAppointment) {
      console.log(response.data?.addNotificationsToAppointment);
      message.success(t("notification:add_notification_success"));
      setDrawerOpen(false);
      setIsModalOpen(true);
    } else {
      message.error(t("notification:add_notification_error"));
    }
  };

  const classNames = {
    body: drawerStyles["my-drawer-body"],
    header: drawerStyles["my-drawer-header"],
    mask: drawerStyles["my-drawer-mask"],
    footer: drawerStyles["my-drawer-footer"],
    // content: styles["my-drawer-content"],
    wrapper: drawerStyles["my-drawer-content-wrapper"],
  };

  const coverDrawerStyles = {
    mask: {
      backdropFilter: "blur(2px) brightness(0.9)",
      background:
        "linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.5))",
    },
    header: {
      padding: "0px",
      border: "transparent",
    },
    content: {
      borderRadius: "35% 35% 0 0",
    },
    body: {
      fontSize: token.fontSizeLG,
    },
    footer: {
      borderTop: `1px solid ${token.colorBorder}`,
    },
    wrapper: {
      background: "transparent",
    },
  };

  const getAvailableNotificationTimes = (
    meetingTime: Date | undefined,
  ): {
    label: string;
    value: number;
  }[] => {
    const timeDifference =
      (meetingTime && dayjs(meetingTime).diff(dayjs(), "minute")) || 0;

    const availableTimes = [
      { label: `${t("common:on_occasion")}`, value: 0 },
      { label: `15 ${t("common:min")}`, value: 15 },
      { label: `30 ${t("common:min")}`, value: 30 },
      { label: `1 ${t("common:hour")}`, value: 60 },
      { label: `1 ${t("common:day")}`, value: 60 * 24 },
      { label: `2 ${t("common:days")}`, value: 60 * 24 * 2 },
    ];

    return availableTimes.filter((option) => option.value <= timeDifference);
  };

  const handleDeleteTimeslot = async (
    properties: ITimeslotProperties,
  ): Promise<void> => {
    const response = await deleteTimeslot({
      variables: {
        timeslotId: properties.id,
      },
    });

    if (response.data?.deleteTimeslot) {
      message.success(t("view:slot_delete_success"));
      setTimeslots(
        timeslots.filter((timeslot) => timeslot.id !== properties.id),
      );
      setSelectedTag(null);
    } else {
      message.error(t("view:slot_delete_error"));
    }
  };

  const handleTagClick = (timeslotId: number): void => {
    setSelectedTag(timeslotId);
  };

  const handleRedirectToMain = async (): Promise<void> =>
    await navigate({ to: "/" });

  const onDateSelect = (value: Dayjs): void => {
    setSelectedDate(value);
  };

  useEffect(() => {
    if (getTimetable?.getTimetable?.timeslots) {
      const timeslotsForSelectedDate =
        getTimetable?.getTimetable?.timeslots.filter((timeslot) =>
          dayjs(timeslot.startAt).isSame(selectedDate, "date"),
        );
      const transformedTimeslots: Timeslot[] = timeslotsForSelectedDate.map(
        (timeslot) => ({
          id: timeslot.id,
          startAt: new Date(timeslot.startAt),
          endAt: new Date(timeslot.endAt),
          createdAt: new Date(timeslot.createdAt),
          updatedAt: new Date(timeslot.updatedAt),
          ownerId: timeslot.ownerId,
        }),
      );
      setTimeslots(transformedTimeslots);
    }
  }, [selectedDate, getTimetable, isClient]);

  useEffect(() => {
    const language = navigator.language;
    if (language === "ru") {
      dayjs.locale("ru");
    } else {
      dayjs.locale("en");
    }
  }, []);

  if (getTimetableLoading) {
    return <div>Loading...</div>;
  }

  if (getTimetableError) {
    if (
      getTimetableError?.message === "Unauthorized" ||
      getTimetableError?.message === "Неавторизован"
    ) {
      authStore.clear();
    }
    return <div>Error: {getTimetableError.message}</div>;
  }

  const handleBookAppointment = async (): Promise<void> => {
    if (selectedTag !== null) {
      console.log("Booking appointment...");
      const response = await bookAppointment({
        variables: {
          createAppointmentInput: {
            timeslotId: selectedTag,
            comment: comment,
          },
        },
      });

      if (response.data?.bookAppointment) {
        message.success(t("view:book_appointment_success"));
        setAppointmentId(response.data.bookAppointment.id);
        setBookedTimeslots([...bookedTimeslots, selectedTag]);
        setComment("");
        if (
          dayjs(
            timeslots.find((timeslot) => timeslot.id === selectedTag)?.startAt,
          ).diff(dayjs(), "minute") > 15
        ) {
          setDrawerOpen(true);
        } else {
          setIsModalOpen(true);
        }
      } else {
        message.error(t("view:book_appointment_error"));
      }
    }
  };

  const checkTimeslot = (timeslot: Timeslot, date: Dayjs): boolean => {
    return dayjs(timeslot.startAt).isSame(date, "date");
  };

  const checkTimeslots = (timeslotsArray: Timeslot[], date: Dayjs): boolean => {
    return timeslotsArray.some((timeslot) => checkTimeslot(timeslot, date));
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const fullCellRender: CalendarProps<Dayjs>["fullCellRender"] = (date) => {
    if (getTimetable?.getTimetable?.timeslots) {
      const isWeekend = date.day() === 6 || date.day() === 0;
      const hasAppointments = checkTimeslots(
        getTimetable.getTimetable.timeslots,
        date,
      );
      const isCurrentMonth = date.isSame(dayjs(), "month");
      const isToday = date.isSame(dayjs(), "date");
      const isSelected = date.isSame(selectedDate, "date");

      return (
        <div
          className={`
          ${calendarStyles.text}
          ${calendarStyles.dateCell}
          ${isWeekend ? calendarStyles.weekend : ""}
          ${isCurrentMonth ? "" : "gray"}
          ${isSelected ? calendarStyles.current : ""}
          ${isToday ? calendarStyles.today : ""}
        `}
        >
          <div
            className={`
            ${
              hasAppointments && isSelected
                ? calendarStyles.current
                : hasAppointments
                  ? calendarStyles.hasTimeslot
                  : ""
            }
              `}
          >
            {date.date()}
          </div>
        </div>
      );
    } else {
      throw new Error("Could not find timeslots");
    }
  };

  const groupedTimeslots = groupTimeslotsByCategory(timeslots);

  return (
    <Flex gap={"large"} vertical style={{ marginBottom: "10vh" }}>
      <div>
        <ConfigProvider
          theme={{
            token: {
              colorBgContainer: "transparent",
            },
          }}
        >
          <Calendar
            style={{
              background: "transparent",
            }}
            fullscreen={false}
            onPanelChange={onPanelChange}
            onSelect={onDateSelect}
            value={selectedDate}
            fullCellRender={fullCellRender}
            headerRender={({ value, onChange }) => {
              const currentMonth = value.month();
              const currentYear = value.year();
              const localMonthNames = dayjs.months();

              const changeMonth = (newMonth: number): void => {
                const newDate = value.clone().month(newMonth);
                onChange(newDate);
              };

              const avatarUrl = isAdmin
                ? authStore!.account!.profile!.avatarUrl
                : getTimetable?.getTimetable?.ownerAvatarUrl;

              const ownerNameFirstLetter = isAdmin
                ? authStore!.account!.profile!.name!.charAt(0).toUpperCase()
                : getTimetable!
                    .getTimetable!.ownerName!.charAt(0)
                    .toUpperCase();

              return (
                <div>
                  <Flex
                    style={{ margin: "10px 0" }}
                    gap={"middle"}
                    align={"center"}
                  >
                    <Avatar
                      style={{
                        backgroundColor: "transparent",
                        backgroundImage: `url('data:image/svg+xml;base64,${avatarUrl}')`,
                        borderRadius: "15px",
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        zIndex: "1",
                        fontSize: "40px",
                        position: "relative",
                        border: "none",
                        minWidth: "90px",
                      }}
                      shape="square"
                      size={90}
                    >
                      <span
                        style={{
                          position: "absolute",
                          zIndex: "2",
                          color: "white",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        {ownerNameFirstLetter}
                      </span>
                    </Avatar>
                    <Flex vertical>
                      <Title style={{ margin: 0 }} level={4}>
                        {`${getTimetable?.getTimetable.ownerName} - ${getTimetable?.getTimetable.title}`}
                      </Title>
                      <Flex vertical>
                        <Text>{getTimetable?.getTimetable.description}</Text>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Row gutter={8} align="middle" justify="space-between">
                    <Col>
                      <Button
                        type="text"
                        icon={<LeftOutlined />}
                        onClick={() => changeMonth(currentMonth - 1)}
                      />
                    </Col>
                    <Col>
                      <div style={{ textAlign: "center" }}>
                        <Title level={4} style={{ margin: 0 }}>
                          {localMonthNames[currentMonth]
                            .charAt(0)
                            .toUpperCase() +
                            localMonthNames[currentMonth].slice(1)}
                        </Title>
                        <Text type="secondary">{currentYear}</Text>
                      </div>
                    </Col>
                    <Col>
                      <Button
                        type="text"
                        icon={<RightOutlined />}
                        onClick={() => changeMonth(currentMonth + 1)}
                      />
                    </Col>
                  </Row>
                </div>
              );
            }}
          />
          <Flex vertical align={"start"}>
            {timeslots.length > 0 ? (
              <Title level={4}>
                {isAdmin ? t("view:your_timeslots") : t("view:choose_timeslot")}
              </Title>
            ) : (
              <Title level={4}>{t("view:no_available_slots")}</Title>
            )}
          </Flex>
          {[Category.Morning, Category.Afternoon, Category.Evening].map(
            (category) =>
              groupedTimeslots[category].length > 0 && (
                <div key={category}>
                  <Title level={5} style={{ marginTop: "15px" }}>
                    {category === "morning"
                      ? t("common:morning")
                      : category === "afternoon"
                        ? t("common:afternoon")
                        : t("common:evening")}
                  </Title>
                  <Flex wrap={"wrap"} justify={"space-around"}>
                    {groupedTimeslots[category]
                      .sort((a, b) => a.startAt.getTime() - b.startAt.getTime())
                      .map((timeslot, index) => (
                        <Timeslot
                          key={index}
                          id={timeslot.id}
                          startsAt={timeslot.startAt}
                          endsAt={timeslot.endAt}
                          isAdmin={isAdmin}
                          selectedTag={selectedTag}
                          handleTagClick={() => handleTagClick(timeslot.id)}
                          handleDeleteTimeslot={(properties) =>
                            handleDeleteTimeslot(properties)
                          }
                        />
                      ))}
                  </Flex>
                </div>
              ),
          )}
        </ConfigProvider>
      </div>
      {isClient && selectedTag && (
        <>
          <TextArea
            style={{ borderRadius: "15px" }}
            placeholder={t("appointment:comment_placeholder")}
            variant="filled"
            value={comment}
            autoSize={{ minRows: 2, maxRows: 4 }}
            maxLength={300}
            onChange={(event) => setComment(event.target.value)}
          />
          <MainButton
            title={t("view:book_appointment_action")}
            onClick={async () => {
              await handleBookAppointment();
            }}
          />
        </>
      )}
      {drawerOpen && isClient && appointmentId && (
        <>
          <Drawer
            onClose={() => {
              setDrawerOpen(false);
              setIsModalOpen(true);
            }}
            closable={true}
            placement="bottom"
            open={drawerOpen}
            classNames={classNames}
            styles={coverDrawerStyles}
          >
            <Flex
              vertical
              align="center"
              style={{ height: "100%", position: "relative" }}
            >
              <img
                src={partyFace}
                style={{
                  width: "70px",
                  height: "70px",
                  position: "absolute",
                  top: -25,
                  left: "50%",
                  // eslint-disable-next-line sonarjs/no-duplicate-string
                  transform: "translateX(-50%)",
                }}
                alt="party face"
              />
              <Title
                level={2}
                style={{
                  margin: "0px",
                  width: "100%",
                  textAlign: "center",
                  position: "absolute",
                  top: 40,
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                {t("appointment:appointment_created_popup")}
              </Title>
              <Text
                strong
                style={{
                  width: "100%",
                  textAlign: "center",
                  position: "absolute",
                  top: 80,
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                {authStore!.account!.profile!.name || t("common:no_name")}
              </Text>
              <Text
                type="secondary"
                style={{
                  width: "80%",
                  textAlign: "center",
                  position: "absolute",
                  top: 100,
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                {`${t("appointment:appointment_start_popup")} : ${formatMeetingTime(
                  new Date(
                    timeslots.find((timeslot) => timeslot.id === selectedTag)
                      ?.startAt as Date,
                  ),
                )}`}
              </Text>
              <Flex
                style={{
                  width: "85%",
                  position: "absolute",
                  top: 130,
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
                gap={"small"}
                align={"center"}
              >
                <Title
                  level={5}
                  style={{
                    margin: "0px",
                  }}
                >
                  {t("appointment:appointment_status")}
                </Title>
                <Title
                  style={{
                    margin: "0px",
                  }}
                  type="warning"
                  level={5}
                >
                  {t("appointment:appointment_status_pending")}
                </Title>
              </Flex>
              <Title
                level={4}
                style={{
                  textAlign: "center",
                  width: "80%",
                  position: "absolute",
                  top: 140,
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                {t("notification:notify_me")}
              </Title>
              <Flex
                gap="small"
                justify="center"
                wrap
                style={{
                  width: "80%",
                  position: "absolute",
                  top: 200,
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                {getAvailableNotificationTimes(
                  timeslots.find((timeslot) => timeslot.id === selectedTag)
                    ?.startAt,
                ).map((option, index) => (
                  <CheckableTag
                    key={index}
                    checked={selectedNotifications.includes(option.value)}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "30px",
                      marginBottom: "5px",
                      borderRadius: "7px",
                      backgroundColor: selectedNotifications.includes(
                        option.value,
                      )
                        ? "#a6a6a6"
                        : "#5B17EA",
                    }}
                    onClick={() => handleNotificationTagClick(option.value)}
                  >
                    <Text strong style={{ color: "white" }}>
                      {option.label}
                    </Text>
                  </CheckableTag>
                ))}
              </Flex>
              <Button
                type="primary"
                style={{
                  width: "90%",
                  position: "absolute",
                  top: 280,
                  left: "50%",
                  borderRadius: "15px",
                  transform: "translateX(-50%)",
                }}
                onClick={async () => {
                  await handleAddNotificationJob();
                }}
              >
                {t("common:apply")}
              </Button>
            </Flex>
          </Drawer>
          <ConfigProvider
            drawer={{
              classNames,
              styles: coverDrawerStyles,
            }}
          />
        </>
      )}
      <Modal
        open={isModalOpen}
        title={t("appointment:appointment_booked_success_title")}
        okText={t("common:to_main")}
        onOk={handleRedirectToMain}
        closable={true}
        onCancel={handleRedirectToMain}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{
          type: "primary",
          style: { width: "100%", borderRadius: "15px" },
        }}
      >
        <Flex gap={"small"} align={"center"} vertical>
          <img
            src={smilingFace}
            style={{
              width: "70px",
              height: "70px",
            }}
            alt="party face"
          />
          <p>{t("appointment:appointment_booked_success_content")}</p>
        </Flex>
      </Modal>
    </Flex>
  );
}

export default TimetableView;
