import { ReactElement } from "react";
import { Tag, Typography } from "antd";
import { format, toZonedTime } from "date-fns-tz";

export interface ITimeslotProperties {
  id: number;
  startsAt: Date;
  endsAt: Date;
  isAdmin: boolean;
  selectedTag: number | null;
  handleTagClick: (id: number) => void;
  handleDeleteTimeslot: (properties: ITimeslotProperties) => Promise<void>;
}

const { Text } = Typography;

export const Timeslot = (properties: ITimeslotProperties): ReactElement => {
  // eslint-disable-next-line new-cap
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const startLocal = toZonedTime(properties.startsAt, timeZone);
  const endLocal = toZonedTime(properties.endsAt, timeZone);

  const formattedStart = format(startLocal, "HH:mm", { timeZone });
  const formattedEnd = format(endLocal, "HH:mm", { timeZone });

  return (
    <Tag
      key={properties.id}
      closable={properties.isAdmin}
      onClose={(event) => {
        event.preventDefault();
        properties.handleDeleteTimeslot(properties);
      }}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "30px",
        marginBottom: "5px",
        borderRadius: "7px",
        backgroundColor:
          properties.selectedTag === properties.id ? "#a6a6a6" : "#108ee9",
      }}
      onClick={() => properties.handleTagClick(properties.id)}
    >
      <Text
        strong
        style={{
          color: properties.selectedTag === properties.id ? "black" : "white",
        }}
      >
        {`${formattedStart} - ${formattedEnd}`}
      </Text>
    </Tag>
  );
};
