import { ReactElement } from "react";
import { Button, Typography } from "antd";

export interface IMainButtonProperties {
  isDisabled?: boolean;
  title: string;
  onClick?: () => Promise<void> | void;
  loading?: boolean;
}

const { Title } = Typography;

export const MainButton = (properties: IMainButtonProperties): ReactElement => {
  return (
    <Button
      disabled={properties.isDisabled}
      type={"primary"}
      size={"large"}
      style={{
        width: "100%",
        backgroundColor: "#5B17EA",
        borderRadius: "15px",
      }}
      onClick={properties.onClick}
      loading={properties.loading}
    >
      <Title
        level={5}
        style={{
          color: "white",
          margin: "0",
        }}
      >
        {properties.title}
      </Title>
    </Button>
  );
};
