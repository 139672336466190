import { JSX, useEffect } from "react";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { retrieveLaunchParams } from "@telegram-apps/sdk";
import { Flex, Spin } from "antd";

import { useLoginViaTelegramMutation } from "../generated/graphql.tsx";
import { useAuthStore } from "../stores/auth.store.ts";

type LoginParameters = {
  viewOwnerId?: string | undefined;
};

const { initDataRaw } = retrieveLaunchParams();

export const Route = createFileRoute("/login")({
  component: Login,
  validateSearch: (parameters: LoginParameters): LoginParameters => {
    return parameters;
  },
});

function Login(): JSX.Element {
  const parameters = Route.useSearch();
  const navigate = useNavigate();

  const authStore = useAuthStore();
  console.log("authStore:", authStore);

  useEffect(() => {
    console.log("authStore.token:", authStore.token);
    if (!authStore.token || !authStore.account?.id) {
      console.log("Logging in");
      handleLogin();
    }
  }, [authStore]);

  const [login, { reset }] = useLoginViaTelegramMutation();

  const handleLogin = async (): Promise<void> => {
    if (!initDataRaw) return;

    try {
      console.log("HANDLE LOGIN", initDataRaw);

      const createMessageResponse = await login({
        variables: {
          initLoadData: initDataRaw,
        },
      });
      console.log("createMessageResponse:", createMessageResponse);

      if (createMessageResponse.data?.loginViaTelegram) {
        reset();
        authStore.setToken(createMessageResponse.data.loginViaTelegram.token);
        authStore.setAccount({
          ...createMessageResponse.data.loginViaTelegram.account,
          profile: {
            ...createMessageResponse.data.loginViaTelegram.account.profile,
          },
        });
        await handleGoBack();
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const handleGoBack = async (): Promise<void> => {
    await (parameters.viewOwnerId
      ? navigate({
          to: "/timetable/view",
          search: {
            ownerId: parameters.viewOwnerId,
          },
        })
      : navigate({
          to: "/",
        }));
  };

  return (
    <Flex style={{ width: "100%" }} align={"center"} justify={"center"}>
      <Spin size="large" tip={"Авторизуем вас"} />
    </Flex>
  );
}
