// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable unicorn/no-null */
import { JSX, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ClockCircleOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import {
  Avatar,
  Button,
  Flex,
  Input,
  message,
  Space,
  Tag,
  TimePicker,
  Typography,
} from "antd";
import dayjs, { Dayjs } from "dayjs";

import { CreatedTimetable } from "../../components/created-timetable.tsx";
import { MainButton } from "../../components/main-button.tsx";
import {
  useCreatePreviewTimetableMutation,
  useCreateTimetableMutation,
  Weekday,
  WorkingDayInput,
} from "../../generated/graphql";
import { useAuthStore } from "../../stores/auth.store";
import { IPreviewTimetable, useDataStore } from "../../stores/data.store.ts";

type CreateParameters = {
  ownerId: string;
};

export const Route = createFileRoute("/timetable/create")({
  component: TimetableCreate,
  validateSearch: (parameters: CreateParameters): CreateParameters => {
    if (!parameters.ownerId) {
      throw new Error("Owner ID is missing");
    }
    return parameters;
  },
});

const { Title, Text } = Typography;
const { TextArea } = Input;

function TimetableCreate(): JSX.Element {
  const { t } = useTranslation(["timetable", "create"]);
  const navigate = useNavigate();
  const format = "HH:mm";
  const dataStore = useDataStore();
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [selectedDays, setSelectedDays] = useState<Weekday[]>([
    Weekday.Monday,
    Weekday.Tuesday,
    Weekday.Wednesday,
    Weekday.Thursday,
    Weekday.Friday,
  ]);
  const [workdays, setWorkdays] = useState<WorkingDayInput[]>([]);
  const [durationInMiliseconds, setDurationInMiliseconds] = useState<number>(
    30 * 60 * 1000,
  );
  const [isCreating, setIsCreating] = useState(false);
  const [create] = useCreateTimetableMutation();
  const [preview] = useCreatePreviewTimetableMutation();
  const [timeRange, setTimeRange] = useState<[Dayjs, Dayjs]>([
    dayjs().hour(9).minute(0).second(0),
    dayjs().hour(18).minute(0).second(0),
  ]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [link, setLink] = useState<string | null>(null);

  const authStore = useAuthStore();

  const weekdays: { label: string; value: Weekday }[] = [
    { label: t("common:monday_short"), value: Weekday.Monday },
    { label: t("common:tuesday_short"), value: Weekday.Tuesday },
    { label: t("common:wednesday_short"), value: Weekday.Wednesday },
    { label: t("common:thursday_short"), value: Weekday.Thursday },
    { label: t("common:friday_short"), value: Weekday.Friday },
    { label: t("common:saturday_short"), value: Weekday.Saturday },
    { label: t("common:sunday_short"), value: Weekday.Sunday },
  ];

  useEffect(() => {
    if (!authStore.token || !authStore.account?.id) {
      navigate({
        to: "/login",
      });
    }
  }, [authStore]);

  const handleCreate = async (): Promise<void> => {
    if (!title.trim()) {
      message.error(t("timetable:timetable_title_required"));
      return;
    }

    if (workdays.length === 0) {
      message.error(t("timetable:timetable_intervals_required"));
      return;
    }

    setIsCreating(true);

    try {
      const workdaysInUTC = workdays.map((workday) => {
        const startTimeLocal = dayjs()
          .hour(workday.timeRange.startTimeInHours)
          .minute(workday.timeRange.startMinutes);
        const endTimeLocal = dayjs()
          .hour(workday.timeRange.endTimeInHours)
          .minute(workday.timeRange.endMinutes);

        return {
          day: workday.day,
          timeRange: {
            startTimeInHours: startTimeLocal.utc().hour(),
            startMinutes: startTimeLocal.utc().minute(),
            endTimeInHours: endTimeLocal.utc().hour(),
            endMinutes: endTimeLocal.utc().minute(),
          },
        };
      });

      const response = await create({
        variables: {
          createTimetableInput: {
            title,
            description,
            startDate: new Date().toISOString(),
            endDate: new Date(
              Date.now() + 150 * 24 * 60 * 60 * 1000,
            ).toISOString(),
            slotDurationInMs: durationInMiliseconds,
            workingDays: workdaysInUTC,
          },
        },
      });

      if (response.data?.createTimetable) {
        const linkToTimetableView = `https://t.me/kalenda_tg_bot/webapp?startapp=${authStore!.account!.id}`;
        setLink(linkToTimetableView);
        setDrawerOpen(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsCreating(false);
    }
  };

  const handlePreview = async (): Promise<void> => {
    if (!title.trim()) {
      message.error(t("timetable:timetable_title_required"));
      return;
    }

    if (workdays.length === 0) {
      message.error(t("timetable:timetable_intervals_required"));
      return;
    }

    try {
      const workdaysInUTC = workdays.map((workday) => {
        const startTimeLocal = dayjs()
          .hour(workday.timeRange.startTimeInHours)
          .minute(workday.timeRange.startMinutes);
        const endTimeLocal = dayjs()
          .hour(workday.timeRange.endTimeInHours)
          .minute(workday.timeRange.endMinutes);

        return {
          day: workday.day,
          timeRange: {
            startTimeInHours: startTimeLocal.utc().hour(),
            startMinutes: startTimeLocal.utc().minute(),
            endTimeInHours: endTimeLocal.utc().hour(),
            endMinutes: endTimeLocal.utc().minute(),
          },
        };
      });

      const response = await preview({
        variables: {
          createTimetableInput: {
            title,
            description,
            startDate: new Date().toISOString(),
            endDate: new Date(
              Date.now() + 150 * 24 * 60 * 60 * 1000,
            ).toISOString(),
            slotDurationInMs: 30 * 60 * 1000,
            workingDays: workdaysInUTC,
          },
        },
      });

      const previewTimetableData: IPreviewTimetable = {
        ownerId: response.data!.createPreviewTimetable.ownerId,
        title: response.data!.createPreviewTimetable.title,
        description: response.data!.createPreviewTimetable.description || "",
        timeslots: response.data!.createPreviewTimetable!.timeslots!.map(
          (timeslot) => ({
            id: timeslot.id,
            startTime: timeslot.startAt,
            endTime: timeslot.endAt,
            ownerId: timeslot.ownerId,
          }),
        ),
      };

      if (response.data?.createPreviewTimetable) {
        dataStore.setTimetable(previewTimetableData);
        await navigate({
          to: "/timetable/preview",
          search: { ownerId: authStore!.account!.profile!.id!.toString() },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDayClick = (value: Weekday): void => {
    setSelectedDays((previousSelectedDays) =>
      previousSelectedDays.includes(value)
        ? previousSelectedDays.filter((day) => day !== value)
        : [...previousSelectedDays, value],
    );
  };

  const [showInterval, setShowInterval] = useState<boolean>(true);

  const toggleShowInterval = useCallback((): void => {
    setShowInterval((previous) => !previous);
  }, []);

  const toggleIntervalIcon = useMemo(() => {
    return showInterval ? <MinusCircleOutlined /> : <PlusCircleOutlined />;
  }, [showInterval]);

  const buttonStyle = {
    borderRadius: "15px",
    display: "flex",
    justifyContent: "start",
  };

  const rangePickerStyle = {
    borderRadius: "15px",
  };

  const handleRemove = (index: number): void => {
    setWorkdays((previousWorkdays) =>
      previousWorkdays.filter((_, index_) => index_ !== index),
    );
  };

  const firstLetter = authStore!
    .account!.profile!.name!.charAt(0)
    .toUpperCase();

  return (
    <Flex vertical gap={"middle"} style={{ marginBottom: "45px" }}>
      <Flex
        vertical
        align={"center"}
        gap={"large"}
        style={{ overflow: "hidden", paddingBottom: "10px" }}
      >
        <Flex vertical align={"center"}>
          <Title level={5}>{t("timetable:timetable_create_title")}</Title>
          <Text type="secondary" style={{ textAlign: "center" }}>
            {t("timetable:timetable_create_description")}
          </Text>
        </Flex>
        <Avatar
          style={{
            backgroundColor: "transparent",
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url('data:image/svg+xml;base64,${authStore!.account!.profile!.avatarUrl}')`,
            borderRadius: "15px",
            zIndex: "1",
            fontSize: "40px",
            position: "relative",
            overflow: "hidden",
            border: "none",
          }}
          shape="square"
          size={100}
          icon={
            authStore?.account?.profile?.avatarUrl ? undefined : (
              <UserOutlined />
            )
          }
        >
          <span
            style={{
              position: "absolute",
              zIndex: "2",
              color: "white",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {firstLetter}
          </span>
        </Avatar>
        <Input
          style={{ borderRadius: "15px" }}
          placeholder={t("timetable:timetable_name_placeholder")}
          value={title}
          variant="filled"
          maxLength={50}
          onChange={(event) => setTitle(event.target.value)}
        />
        <TextArea
          style={{ borderRadius: "15px" }}
          placeholder={t("timetable:timetable_description_placeholder")}
          variant="filled"
          value={description}
          autoSize={{ minRows: 2, maxRows: 4 }}
          maxLength={80}
          onChange={(event) => setDescription(event.target.value)}
        />
        <Space.Compact>
          <Flex gap={"small"}>
            {weekdays.map(({ label, value }) => (
              <Button
                type={"text"}
                key={value}
                onClick={() => handleDayClick(value)}
                style={{
                  backgroundColor: selectedDays.includes(value)
                    ? "#5B17EA"
                    : "#f0f0f0",
                  color: selectedDays.includes(value) ? "white" : "black",
                  width: "37px",
                  height: "37px",
                  borderRadius:
                    value === Weekday.Monday
                      ? "15px 0 0 15px"
                      : value === Weekday.Sunday
                        ? "0 15px 15px 0"
                        : "5px",
                }}
              >
                {label}
              </Button>
            ))}
          </Flex>
        </Space.Compact>
        <Text type={"secondary"}>
          {t("timetable:timetable_duration_placeholder")}
        </Text>
        <Input
          prefix={<ClockCircleOutlined />}
          style={{ borderRadius: "15px" }}
          placeholder="Filled"
          variant="filled"
          value={
            durationInMiliseconds / (60 * 1000) > 0
              ? `${durationInMiliseconds / (60 * 1000)}`
              : ""
          }
          onChange={(event) =>
            setDurationInMiliseconds(Number(event.target.value) * 60 * 1000)
          }
        />
        <Flex style={{ width: "100%" }} vertical gap="small">
          <Button
            size="large"
            type="text"
            style={buttonStyle}
            icon={toggleIntervalIcon}
            onClick={toggleShowInterval}
          >
            {t("timetable:timetable_add_intervals")}
          </Button>
          {showInterval && (
            <>
              <TimePicker.RangePicker
                style={rangePickerStyle}
                format={format}
                variant="filled"
                value={timeRange}
                onChange={(values) => {
                  if (values && values[0] && values[1]) {
                    setTimeRange(values as [Dayjs, Dayjs]);
                  }
                }}
              />
              <Button
                style={{ borderRadius: "15px" }}
                onClick={() => {
                  const duration = timeRange[1].diff(
                    timeRange[0],
                    "milliseconds",
                  );
                  if (duration < durationInMiliseconds) {
                    message.error(
                      t("timetable:timetable_min_interval_limit_error"),
                    );
                    return;
                  }
                  if (selectedDays.length === 0) {
                    message.error(t("timetable:timetable_select_days_error"));
                    return;
                  }
                  const newWorkdays = selectedDays.map((day) => ({
                    day,
                    timeRange: {
                      endMinutes: timeRange[1].minute(),
                      endTimeInHours: timeRange[1].hour(),
                      startMinutes: timeRange[0].minute(),
                      startTimeInHours: timeRange[0].hour(),
                    },
                  }));
                  setWorkdays([...workdays, ...newWorkdays]);
                }}
                type={"primary"}
              >
                {t("common:add")}
              </Button>
            </>
          )}
        </Flex>
        <Flex vertical gap={"small"} style={{ width: "100%" }}>
          <Flex gap={"small"} justify={"start"} wrap={"wrap"}>
            {workdays.map((interval, index) => (
              <Tag
                closable
                key={index}
                style={{
                  marginBottom: "5px",
                  padding: "5px",
                  borderRadius: "10px",
                  backgroundColor: "#5B17EA",
                }}
                bordered={false}
                color="#5B17EA"
                onClose={(event) => {
                  event.preventDefault();
                  handleRemove(index);
                }}
              >
                <Text style={{ color: "white" }}>
                  {`${
                    weekdays.find((day) => day.value === interval.day)?.label
                  }, ${interval.timeRange.startTimeInHours.toString().padStart(2, "0")}:${interval.timeRange.startMinutes.toString().padStart(2, "0")} - ${interval.timeRange.endTimeInHours
                    .toString()
                    .padStart(
                      2,
                      "0",
                    )}:${interval.timeRange.endMinutes.toString().padStart(2, "0")}`}
                </Text>
              </Tag>
            ))}
          </Flex>
        </Flex>
        <Button
          type={"primary"}
          size={"large"}
          // TODO: Fix preview
          style={{ borderRadius: "15px", width: "100%", display: "none" }}
          onClick={handlePreview}
        >
          <Title
            level={5}
            style={{
              color: "white",
              margin: "0",
            }}
          >
            {t("timetable:timetable_preview")}
          </Title>
        </Button>
        <MainButton
          isDisabled={!workdays}
          loading={isCreating}
          title={t("timetable:timetable_create_title")}
          onClick={handleCreate}
        />
        {link && (
          <CreatedTimetable
            link={link}
            open={drawerOpen}
            onClose={async () => {
              setDrawerOpen(false);
              await navigate({
                to: "/",
              });
            }}
          />
        )}
      </Flex>
    </Flex>
  );
}
