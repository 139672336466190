import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { Account, Profile } from "../generated/graphql.tsx";

type IAuthState = {
  token: string | undefined;
  setToken: (token: string | undefined) => void;
  googleToken: string | undefined;
  setGoogleToken: (googleToken: string | undefined) => void;
  account:
    | (Omit<Partial<Account>, "profile"> & { profile: Partial<Profile> })
    | undefined;
  setAccount: (
    account:
      | (Omit<Partial<Account>, "profile"> & { profile: Partial<Profile> })
      | undefined,
  ) => void;
  clear: () => void;
};

export const useAuthStore = create(
  persist<IAuthState>(
    (set) => ({
      token: undefined,
      setToken: (token: string | undefined): void => {
        console.log("Token:", token); // log token
        set({ token });
      },
      googleToken: undefined,
      setGoogleToken: (googleToken: string | undefined): void => {
        console.log("Google Token:", googleToken); // log google token
        set({ googleToken });
      },
      account: undefined,
      setAccount: (
        account:
          | (Omit<Partial<Account>, "profile"> & { profile: Partial<Profile> })
          | undefined,
      ): void => {
        console.log("Account:", account); // log account
        set({ account });
      },
      clear: (): void => set({ token: undefined, account: undefined }),
    }),
    {
      name: "auth-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
