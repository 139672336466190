// noinspection TypeScriptValidateTypes

import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "@tanstack/react-router";
import { initUtils } from "@telegram-apps/sdk";
import { Button, ConfigProvider, Drawer, Flex, Typography } from "antd";
import { createStyles, useTheme } from "antd-style";

import partyFace from "../../src/assets/party-face.svg";

export interface ICreatedTimetableProperties {
  link: string;
  open: boolean;
  onClose: () => void;
}

const utils = initUtils();

const { Title } = Typography;

const useStyle = createStyles(({ token }) => ({
  "my-drawer-body": {
    fontSize: token.fontSizeLG,
  },
  "my-drawer-header": {
    borderBottom: "transparent",
  },
  "my-drawer-mask": {
    backdropFilter: "blur(2px) brightness(0.9)",
    background:
      "linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.5))",
  },
  "my-drawer-footer": {
    color: token.colorPrimary,
  },
  "my-drawer-content-wrapper": {
    borderRadius: "35% 35% 0 0",
    overflow: "hidden",
    boxShadow: `0 -10px 20px -5px rgba(0, 0, 0, 0.5)`,
  },
}));

export const CreatedTimetable = (
  properties: ICreatedTimetableProperties,
): ReactElement => {
  const navigate = useNavigate();

  const { t } = useTranslation([
    "appointment",
    "timetable",
    "common",
    "view",
    "notification",
  ]);
  const { styles } = useStyle();
  const token = useTheme();

  const classNames = {
    body: styles["my-drawer-body"],
    header: styles["my-drawer-header"],
    mask: styles["my-drawer-mask"],
    footer: styles["my-drawer-footer"],
    // content: styles["my-drawer-content"],
    wrapper: styles["my-drawer-content-wrapper"],
  };

  const drawerStyles = {
    mask: {
      backdropFilter: "blur(2px) brightness(0.9)",
      background:
        "linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.5))",
    },
    header: {
      padding: "0px",
      border: "transparent",
    },
    content: {
      borderRadius: "35% 35% 0 0",
    },
    body: {
      fontSize: token.fontSizeLG,
    },
    footer: {
      borderTop: `1px solid ${token.colorBorder}`,
    },
    wrapper: {
      height: "250px",
      background: "transparent",
    },
  };

  return (
    <>
      <Drawer
        placement="bottom"
        onClose={properties.onClose}
        open={properties.open}
        classNames={classNames}
        styles={drawerStyles}
      >
        <Flex
          vertical
          align="center"
          style={{ height: "100%", position: "relative" }}
        >
          <img
            src={partyFace}
            style={{
              width: "70px",
              height: "70px",
              position: "absolute",
              top: -25,
              left: "50%",
              // eslint-disable-next-line sonarjs/no-duplicate-string
              transform: "translateX(-50%)",
            }}
            alt="party face"
          />
          <Title
            level={2}
            style={{
              margin: "0px",
              width: "100%",
              textAlign: "center",
              position: "absolute",
              top: 50,
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            {t("timetable:timetable_share_popup_title")}
          </Title>
          <Title
            level={5}
            style={{
              width: "100%",
              textAlign: "center",
              position: "absolute",
              top: 80,
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            {t("timetable:timetable_share_popup_description")}
          </Title>
          <Button
            type={"primary"}
            style={{
              marginBottom: "5px",
              backgroundColor: "#5B17EA",
              width: "100%",
              borderRadius: "15px",
              top: 150,
              left: "50%",
              transform: "translateX(-50%)",
            }}
            onClick={async () => {
              utils.shareURL(
                properties.link,
                t("timetable:timetable_share_message"),
              );
              await navigate({
                to: "/",
              });
            }}
          >
            {t("timetable:timetable_share_button")}
          </Button>
        </Flex>
      </Drawer>
      <ConfigProvider
        drawer={{
          classNames,
          styles: drawerStyles,
        }}
      />
    </>
  );
};
