// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable unicorn/no-null */
import { JSX, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LeftOutlined, RightOutlined, UserOutlined } from "@ant-design/icons";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import {
  Avatar,
  Button,
  Calendar,
  CalendarProps,
  Col,
  Flex,
  message,
  Row,
  Typography,
} from "antd";
import dayjs, { Dayjs } from "dayjs";

import "dayjs/locale/en";
import "dayjs/locale/ru";

import { useCalendarStyles } from "../../common/calendar-styles.ts";
import { ITimeslotProperties, Timeslot } from "../../components/timeslot.tsx";
import {
  useDeleteTimeslotMutation,
  useGetTimetableQuery,
} from "../../generated/graphql.tsx";
import { useAuthStore } from "../../stores/auth.store.ts";
import { IPreviewTimeslot, useDataStore } from "../../stores/data.store.ts";

const { Text, Title } = Typography;

export interface Timeslot {
  id: number;
  startAt: Date;
  endAt: Date;
  createdAt: Date;
  updatedAt: Date;
  ownerId: number;
}

type ViewParameters = {
  ownerId: string;
};

enum Category {
  Morning = "morning",
  Afternoon = "afternoon",
  Evening = "evening",
}

export const Route = createFileRoute("/timetable/preview")({
  component: TimetablePreview,
  validateSearch: (parameters: ViewParameters): ViewParameters => {
    if (!parameters.ownerId) {
      throw new Error("Owner ID is missing");
    }
    return parameters;
  },
});

export interface IGroupedTimeslots {
  morning: IPreviewTimeslot[];
  afternoon: IPreviewTimeslot[];
  evening: IPreviewTimeslot[];
}

const onPanelChange = (
  value: Dayjs,
  mode: CalendarProps<Dayjs>["mode"],
): void => {
  console.log(value.format("YYYY-MM-DD"), mode);
};

const categorizeTimeslot = (time: Date | string): Category => {
  if (!(time instanceof Date)) {
    // eslint-disable-next-line no-param-reassign
    time = new Date(time);
  }

  console.log("StartAt", time);
  const hour = time.getHours();

  if (hour >= 6 && hour < 12) return Category.Morning;
  if (hour >= 12 && hour < 18) return Category.Afternoon;
  return Category.Evening;
};

const groupTimeslotsByCategory = (
  timeslots: IPreviewTimeslot[],
): IGroupedTimeslots => {
  const grouped: IGroupedTimeslots = {
    morning: [],
    afternoon: [],
    evening: [],
  };

  for (const timeslot of timeslots) {
    const category = categorizeTimeslot(timeslot.startTime);
    grouped[category].push(timeslot);
  }

  return grouped;
};

function TimetablePreview(): JSX.Element {
  const { t } = useTranslation(["appointment", "timetable", "common", "view"]);
  const navigate = useNavigate();
  const searchParameters = Route.useSearch();
  const ownerId: string = searchParameters.ownerId;
  const dataStore = useDataStore();

  const authStore = useAuthStore();

  useEffect(() => {
    if (!authStore.token || !authStore.account?.id) {
      navigate({
        to: "/login",
      });
    }
  }, [authStore]);

  useEffect(() => {
    if (!dataStore.timetable) {
      throw new Error("Timetable is missing");
    }
  }, [dataStore]);

  const {
    data: getTimetable,
    loading: getTimetableLoading,
    error: getTimetableError,
  } = useGetTimetableQuery({
    variables: { ownerId: Number.parseInt(ownerId) },
    skip: !ownerId,
  });

  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [timeslots, setTimeslots] = useState<IPreviewTimeslot[]>([]);
  const [selectedTag, setSelectedTag] = useState<number | null>(null);

  const [deleteTimeslot] = useDeleteTimeslotMutation();

  const handleDeleteTimeslot = async (
    properties: ITimeslotProperties,
  ): Promise<void> => {
    const response = await deleteTimeslot({
      variables: {
        timeslotId: properties.id,
      },
    });

    if (response.data?.deleteTimeslot) {
      message.success(t("view:slot_delete_success"));
      setTimeslots(
        timeslots.filter((timeslot) => timeslot.id !== properties.id),
      );
      setSelectedTag(null);
    } else {
      message.error(t("view:slot_delete_error"));
    }
  };

  const { styles } = useCalendarStyles();

  const handleTagClick = (index: number): void => {
    setSelectedTag(index);
  };

  const onDateSelect = (value: Dayjs): void => {
    setSelectedDate(value);
  };

  useEffect(() => {
    if (dataStore!.timetable!.timeslots) {
      const timeslotsForSelectedDate = dataStore!.timetable!.timeslots.filter(
        (timeslot) => dayjs(timeslot.startTime).isSame(selectedDate, "date"),
      );
      const transformedTimeslots: IPreviewTimeslot[] =
        timeslotsForSelectedDate.map((timeslot) => ({
          id: timeslot.id,
          startTime: new Date(timeslot.startTime),
          endTime: new Date(timeslot.endTime),
          ownerId: timeslot.ownerId,
        }));
      setTimeslots(transformedTimeslots);
    }
  }, [selectedDate, getTimetable]);

  useEffect(() => {
    const language = navigator.language;
    console.log(language);
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (language) {
      case "ru": {
        dayjs.locale("ru");
        break;
      }
      default: {
        dayjs.locale("en");
        break;
      }
    }
  }, []);

  if (getTimetableLoading) {
    return <div>Loading...</div>;
  }

  if (getTimetableError) {
    return <div>Error: {getTimetableError.message}</div>;
  }

  const checkTimeslot = (timeslot: Timeslot, date: Dayjs): boolean => {
    const timeslotDate = dayjs(timeslot.startAt);
    return timeslotDate.isSame(date, "date");
  };

  const checkTimeslots = (timeslotsArray: Timeslot[], date: Dayjs): boolean => {
    return timeslotsArray.some((timeslot) => checkTimeslot(timeslot, date));
  };

  const fullCellRender: CalendarProps<Dayjs>["fullCellRender"] = (date) => {
    if (getTimetable?.getTimetable?.timeslots) {
      const isWeekend = date.day() === 6 || date.day() === 0;
      const hasAppointments = checkTimeslots(
        getTimetable.getTimetable.timeslots,
        date,
      );
      const isCurrentMonth = date.isSame(dayjs(), "month");
      const isToday = date.isSame(dayjs(), "date");
      const isSelected = date.isSame(selectedDate, "date");

      return (
        <div
          className={`
          ${styles.text}
          ${styles.dateCell}
          ${isWeekend ? styles.weekend : ""}
          ${isCurrentMonth ? "" : "gray"}
          ${isSelected ? styles.current : ""}
          ${isToday ? styles.today : ""}
        `}
        >
          <div
            className={`
            ${hasAppointments ? styles.hasTimeslot : ""}
          `}
          >
            {date.date()}
          </div>
        </div>
      );
    } else {
      throw new Error("Could not find timeslots");
    }
  };

  const groupedTimeslots = groupTimeslotsByCategory(timeslots);

  return (
    <Flex gap={"large"} vertical>
      <div>
        <Calendar
          style={{
            background: "transparent",
          }}
          fullscreen={false}
          onPanelChange={onPanelChange}
          onSelect={onDateSelect}
          value={selectedDate}
          fullCellRender={fullCellRender}
          headerRender={({ value, onChange }) => {
            const currentMonth = value.month();
            const currentYear = value.year();
            const localMonthNames = dayjs.months();

            const changeMonth = (newMonth: number): void => {
              const newDate = value.clone().month(newMonth);
              onChange(newDate);
            };

            const firstLetter = authStore!
              .account!.profile!.name!.charAt(0)
              .toUpperCase();

            return (
              <div>
                <Flex
                  style={{ margin: "10px 0" }}
                  gap={"middle"}
                  align={"center"}
                >
                  <Avatar
                    style={{
                      minWidth: "90px",
                      backgroundColor: "transparent",
                      backgroundImage: `url('data:image/svg+xml;base64,${authStore!.account!.profile!.avatarUrl}')`,
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      borderRadius: "15px",
                      zIndex: "1",
                      fontSize: "40px",
                      position: "relative",
                      overflow: "hidden",
                      border: "none",
                    }}
                    shape="square"
                    size={100}
                    icon={
                      authStore?.account?.profile?.avatarUrl ? undefined : (
                        <UserOutlined />
                      )
                    }
                  >
                    <span
                      style={{
                        position: "absolute",
                        zIndex: "2",
                        color: "white",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      {firstLetter}
                    </span>
                  </Avatar>
                  <Flex vertical>
                    <Title style={{ margin: 0 }} level={4}>
                      {dataStore!.timetable!.title ||
                        t("timetable:timetable_no_title")}
                    </Title>
                    <Flex vertical>
                      <Text>
                        {dataStore!.timetable!.description ||
                          t("timetable:timetable_no_description")}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
                <Row gutter={8} align="middle" justify="space-between">
                  <Col>
                    <Button
                      type="text"
                      icon={<LeftOutlined />}
                      onClick={() => changeMonth(currentMonth - 1)}
                    />
                  </Col>
                  <Col>
                    <div style={{ textAlign: "center" }}>
                      <Title level={4} style={{ margin: 0 }}>
                        {/* eslint-disable-next-line security/detect-object-injection */}
                        {localMonthNames[currentMonth].charAt(0).toUpperCase() +
                          localMonthNames[currentMonth].slice(1)}
                      </Title>
                      <Text type="secondary">{currentYear}</Text>
                    </div>
                  </Col>
                  <Col>
                    <Button
                      type="text"
                      icon={<RightOutlined />}
                      onClick={() => changeMonth(currentMonth + 1)}
                    />
                  </Col>
                </Row>
              </div>
            );
          }}
        />
        <Flex vertical align={"start"}>
          {timeslots.length > 0 ? (
            <Title level={4}>{t("view:choose_available_slots")}</Title>
          ) : (
            <Title level={4}>{t("view:no_available_slots")}</Title>
          )}
        </Flex>
        {[Category.Morning, Category.Afternoon, Category.Evening].map(
          (category) =>
            groupedTimeslots[category].length > 0 && (
              <div key={category}>
                <Title level={5} style={{ marginTop: "15px" }}>
                  {category === "morning"
                    ? t("common:morning")
                    : category === "afternoon"
                      ? t("common:afternoon")
                      : t("common:evening")}
                </Title>
                <Flex
                  style={{
                    overflow: "auto",
                  }}
                >
                  {groupedTimeslots[category]
                    .sort((a, b) => a.startTime.getTime() - b.endTime.getTime())
                    .map((timeslot, index) => (
                      <Timeslot
                        key={index}
                        id={timeslot.id}
                        startsAt={timeslot.startTime}
                        endsAt={timeslot.endTime}
                        isAdmin={false}
                        selectedTag={selectedTag}
                        handleTagClick={handleTagClick}
                        handleDeleteTimeslot={(properties) =>
                          handleDeleteTimeslot(properties)
                        }
                      />
                    ))}
                </Flex>
              </div>
            ),
        )}
      </div>
    </Flex>
  );
}

export default TimetablePreview;
