import { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "@tanstack/react-router";
import { Flex } from "antd";

import logo from "../../.github/logo.svg";
import logoDark from "../../.github/logo-dark.svg";
import styles from "../routes/__root.module.scss";

export const Header = (): ReactElement => {
  const navigate = useNavigate();

  const [isDarkTheme, setIsDarkTheme] = useState<boolean>(
    window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches,
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleThemeChange = (event: MediaQueryListEvent): void => {
      setIsDarkTheme(event.matches);
    };
    mediaQuery.addEventListener("change", handleThemeChange);
    return (): void =>
      mediaQuery.removeEventListener("change", handleThemeChange);
  }, []);

  const logoImage = isDarkTheme ? logoDark : logo;

  return (
    <div className={styles.header}>
      <div className={styles.glassEffect}></div>
      <Flex className={styles.content}>
        <img
          src={logoImage}
          alt="logo"
          className={styles.logo}
          onClick={async (): Promise<void> => {
            await navigate({
              to: "/",
            });
          }}
        />
      </Flex>
    </div>
  );
};
