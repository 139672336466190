import { createStyles } from "antd-style";

export const useDrawerStyles = createStyles(({ token }) => ({
  "my-drawer-body": {
    fontSize: token.fontSizeLG,
  },
  "my-drawer-header": {
    borderBottom: "transparent",
  },
  "my-drawer-mask": {
    backdropFilter: "blur(2px) brightness(0.9)",
    background:
      "linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.5))",
  },
  "my-drawer-footer": {
    color: token.colorPrimary,
  },
  "my-drawer-content-wrapper": {
    borderRadius: "35% 35% 0 0",
    overflow: "hidden",
    boxShadow: `0 -10px 20px -5px rgba(0, 0, 0, 0.5)`,
  },
}));
