/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type Account = {
  __typename?: 'Account';
  _count: AccountCount;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  googleSessions?: Maybe<Array<GoogleSession>>;
  id: Scalars['Int']['output'];
  profile?: Maybe<Profile>;
  profileId?: Maybe<Scalars['Int']['output']>;
  sessions?: Maybe<Array<AccountSession>>;
  telegramId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AccountCount = {
  __typename?: 'AccountCount';
  googleSessions: Scalars['Int']['output'];
  sessions: Scalars['Int']['output'];
};

export type AccountCreateManyProfileInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  passwordHash: Scalars['String']['input'];
  telegramId: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AccountCreateManyProfileInputEnvelope = {
  data: Array<AccountCreateManyProfileInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccountCreateNestedManyWithoutProfileInput = {
  connect?: InputMaybe<Array<AccountWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AccountCreateOrConnectWithoutProfileInput>>;
  create?: InputMaybe<Array<AccountCreateWithoutProfileInput>>;
  createMany?: InputMaybe<AccountCreateManyProfileInputEnvelope>;
};

export type AccountCreateOrConnectWithoutProfileInput = {
  create: AccountCreateWithoutProfileInput;
  where: AccountWhereUniqueInput;
};

export type AccountCreateWithoutProfileInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  googleSessions?: InputMaybe<GoogleSessionCreateNestedManyWithoutAccountInput>;
  passwordHash: Scalars['String']['input'];
  sessions?: InputMaybe<AccountSessionCreateNestedManyWithoutAccountInput>;
  telegramId: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AccountListRelationFilter = {
  every?: InputMaybe<AccountWhereInput>;
  none?: InputMaybe<AccountWhereInput>;
  some?: InputMaybe<AccountWhereInput>;
};

export type AccountRelationFilter = {
  is?: InputMaybe<AccountWhereInput>;
  isNot?: InputMaybe<AccountWhereInput>;
};

export type AccountScalarWhereInput = {
  AND?: InputMaybe<Array<AccountScalarWhereInput>>;
  NOT?: InputMaybe<Array<AccountScalarWhereInput>>;
  OR?: InputMaybe<Array<AccountScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  passwordHash?: InputMaybe<StringFilter>;
  profileId?: InputMaybe<IntNullableFilter>;
  telegramId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AccountSession = {
  __typename?: 'AccountSession';
  account: Account;
  accountId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  ipAddr: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userAgent?: Maybe<Scalars['String']['output']>;
};

export type AccountSessionCreateManyAccountInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  ipAddr: Scalars['String']['input'];
  token: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
};

export type AccountSessionCreateManyAccountInputEnvelope = {
  data: Array<AccountSessionCreateManyAccountInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccountSessionCreateNestedManyWithoutAccountInput = {
  connect?: InputMaybe<Array<AccountSessionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AccountSessionCreateOrConnectWithoutAccountInput>>;
  create?: InputMaybe<Array<AccountSessionCreateWithoutAccountInput>>;
  createMany?: InputMaybe<AccountSessionCreateManyAccountInputEnvelope>;
};

export type AccountSessionCreateOrConnectWithoutAccountInput = {
  create: AccountSessionCreateWithoutAccountInput;
  where: AccountSessionWhereUniqueInput;
};

export type AccountSessionCreateWithoutAccountInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt: Scalars['DateTime']['input'];
  ipAddr: Scalars['String']['input'];
  token: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
};

export type AccountSessionListRelationFilter = {
  every?: InputMaybe<AccountSessionWhereInput>;
  none?: InputMaybe<AccountSessionWhereInput>;
  some?: InputMaybe<AccountSessionWhereInput>;
};

export type AccountSessionScalarWhereInput = {
  AND?: InputMaybe<Array<AccountSessionScalarWhereInput>>;
  NOT?: InputMaybe<Array<AccountSessionScalarWhereInput>>;
  OR?: InputMaybe<Array<AccountSessionScalarWhereInput>>;
  accountId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiresAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  ipAddr?: InputMaybe<StringFilter>;
  token?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userAgent?: InputMaybe<StringNullableFilter>;
};

export type AccountSessionUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  ipAddr?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
};

export type AccountSessionUpdateManyWithWhereWithoutAccountInput = {
  data: AccountSessionUpdateManyMutationInput;
  where: AccountSessionScalarWhereInput;
};

export type AccountSessionUpdateManyWithoutAccountNestedInput = {
  connect?: InputMaybe<Array<AccountSessionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AccountSessionCreateOrConnectWithoutAccountInput>>;
  create?: InputMaybe<Array<AccountSessionCreateWithoutAccountInput>>;
  createMany?: InputMaybe<AccountSessionCreateManyAccountInputEnvelope>;
  delete?: InputMaybe<Array<AccountSessionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AccountSessionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AccountSessionWhereUniqueInput>>;
  set?: InputMaybe<Array<AccountSessionWhereUniqueInput>>;
  update?: InputMaybe<Array<AccountSessionUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: InputMaybe<Array<AccountSessionUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: InputMaybe<Array<AccountSessionUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type AccountSessionUpdateWithWhereUniqueWithoutAccountInput = {
  data: AccountSessionUpdateWithoutAccountInput;
  where: AccountSessionWhereUniqueInput;
};

export type AccountSessionUpdateWithoutAccountInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  ipAddr?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
};

export type AccountSessionUpsertWithWhereUniqueWithoutAccountInput = {
  create: AccountSessionCreateWithoutAccountInput;
  update: AccountSessionUpdateWithoutAccountInput;
  where: AccountSessionWhereUniqueInput;
};

export type AccountSessionWhereInput = {
  AND?: InputMaybe<Array<AccountSessionWhereInput>>;
  NOT?: InputMaybe<Array<AccountSessionWhereInput>>;
  OR?: InputMaybe<Array<AccountSessionWhereInput>>;
  account?: InputMaybe<AccountRelationFilter>;
  accountId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiresAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  ipAddr?: InputMaybe<StringFilter>;
  token?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userAgent?: InputMaybe<StringNullableFilter>;
};

export type AccountSessionWhereUniqueInput = {
  AND?: InputMaybe<Array<AccountSessionWhereInput>>;
  NOT?: InputMaybe<Array<AccountSessionWhereInput>>;
  OR?: InputMaybe<Array<AccountSessionWhereInput>>;
  account?: InputMaybe<AccountRelationFilter>;
  accountId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiresAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  ipAddr?: InputMaybe<StringFilter>;
  token?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userAgent?: InputMaybe<StringNullableFilter>;
};

export enum AccountStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE'
}

export type AccountUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  passwordHash?: InputMaybe<Scalars['String']['input']>;
  telegramId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AccountUpdateManyWithWhereWithoutProfileInput = {
  data: AccountUpdateManyMutationInput;
  where: AccountScalarWhereInput;
};

export type AccountUpdateManyWithoutProfileNestedInput = {
  connect?: InputMaybe<Array<AccountWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AccountCreateOrConnectWithoutProfileInput>>;
  create?: InputMaybe<Array<AccountCreateWithoutProfileInput>>;
  createMany?: InputMaybe<AccountCreateManyProfileInputEnvelope>;
  delete?: InputMaybe<Array<AccountWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AccountScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AccountWhereUniqueInput>>;
  set?: InputMaybe<Array<AccountWhereUniqueInput>>;
  update?: InputMaybe<Array<AccountUpdateWithWhereUniqueWithoutProfileInput>>;
  updateMany?: InputMaybe<Array<AccountUpdateManyWithWhereWithoutProfileInput>>;
  upsert?: InputMaybe<Array<AccountUpsertWithWhereUniqueWithoutProfileInput>>;
};

export type AccountUpdateWithWhereUniqueWithoutProfileInput = {
  data: AccountUpdateWithoutProfileInput;
  where: AccountWhereUniqueInput;
};

export type AccountUpdateWithoutProfileInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  googleSessions?: InputMaybe<GoogleSessionUpdateManyWithoutAccountNestedInput>;
  passwordHash?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<AccountSessionUpdateManyWithoutAccountNestedInput>;
  telegramId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AccountUpsertWithWhereUniqueWithoutProfileInput = {
  create: AccountCreateWithoutProfileInput;
  update: AccountUpdateWithoutProfileInput;
  where: AccountWhereUniqueInput;
};

export type AccountWhereInput = {
  AND?: InputMaybe<Array<AccountWhereInput>>;
  NOT?: InputMaybe<Array<AccountWhereInput>>;
  OR?: InputMaybe<Array<AccountWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  googleSessions?: InputMaybe<GoogleSessionListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  passwordHash?: InputMaybe<StringFilter>;
  profile?: InputMaybe<ProfileNullableRelationFilter>;
  profileId?: InputMaybe<IntNullableFilter>;
  sessions?: InputMaybe<AccountSessionListRelationFilter>;
  telegramId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AccountWhereUniqueInput = {
  AND?: InputMaybe<Array<AccountWhereInput>>;
  NOT?: InputMaybe<Array<AccountWhereInput>>;
  OR?: InputMaybe<Array<AccountWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  googleSessions?: InputMaybe<GoogleSessionListRelationFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  passwordHash?: InputMaybe<StringFilter>;
  profile?: InputMaybe<ProfileNullableRelationFilter>;
  profileId?: InputMaybe<IntNullableFilter>;
  sessions?: InputMaybe<AccountSessionListRelationFilter>;
  telegramId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AddNotificationJobInput = {
  appointmentId: Scalars['Float']['input'];
  notifications: Array<NotificationInput>;
  role: AppointmentRole;
  startTime: Scalars['DateTime']['input'];
};

export type Appointment = {
  __typename?: 'Appointment';
  Timeslot: Timeslot;
  _count: AppointmentCount;
  client: Profile;
  clientId: Scalars['Int']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  notifications?: Maybe<Array<Notification>>;
  owner: Profile;
  ownerId: Scalars['Int']['output'];
  profile: Profile;
  status: AppointmentStatus;
  timeslot: Timeslot;
  timeslotId: Scalars['Int']['output'];
  timetable: Timeslot;
  updatedAt: Scalars['DateTime']['output'];
};


export type AppointmentProfileArgs = {
  role: Scalars['String']['input'];
};

export type AppointmentCount = {
  __typename?: 'AppointmentCount';
  notifications: Scalars['Int']['output'];
};

export type AppointmentCreateManyClientInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  ownerId: Scalars['Int']['input'];
  status: AppointmentStatus;
  timeslotId: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AppointmentCreateManyClientInputEnvelope = {
  data: Array<AppointmentCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AppointmentCreateManyOwnerInput = {
  clientId: Scalars['Int']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  status: AppointmentStatus;
  timeslotId: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AppointmentCreateManyOwnerInputEnvelope = {
  data: Array<AppointmentCreateManyOwnerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AppointmentCreateManyTimeslotInput = {
  clientId: Scalars['Int']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  ownerId: Scalars['Int']['input'];
  status: AppointmentStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AppointmentCreateManyTimeslotInputEnvelope = {
  data: Array<AppointmentCreateManyTimeslotInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AppointmentCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AppointmentCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<AppointmentCreateWithoutClientInput>>;
  createMany?: InputMaybe<AppointmentCreateManyClientInputEnvelope>;
};

export type AppointmentCreateNestedManyWithoutOwnerInput = {
  connect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AppointmentCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<AppointmentCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<AppointmentCreateManyOwnerInputEnvelope>;
};

export type AppointmentCreateNestedManyWithoutTimeslotInput = {
  connect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AppointmentCreateOrConnectWithoutTimeslotInput>>;
  create?: InputMaybe<Array<AppointmentCreateWithoutTimeslotInput>>;
  createMany?: InputMaybe<AppointmentCreateManyTimeslotInputEnvelope>;
};

export type AppointmentCreateNestedOneWithoutNotificationsInput = {
  connect?: InputMaybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AppointmentCreateOrConnectWithoutNotificationsInput>;
  create?: InputMaybe<AppointmentCreateWithoutNotificationsInput>;
};

export type AppointmentCreateOrConnectWithoutClientInput = {
  create: AppointmentCreateWithoutClientInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutNotificationsInput = {
  create: AppointmentCreateWithoutNotificationsInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutOwnerInput = {
  create: AppointmentCreateWithoutOwnerInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutTimeslotInput = {
  create: AppointmentCreateWithoutTimeslotInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateWithoutClientInput = {
  Timeslot: TimeslotCreateNestedOneWithoutAppointmentsInput;
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutAppointmentInput>;
  owner: ProfileCreateNestedOneWithoutAppointmentsWhereIOwnerInput;
  status: AppointmentStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AppointmentCreateWithoutNotificationsInput = {
  Timeslot: TimeslotCreateNestedOneWithoutAppointmentsInput;
  client: ProfileCreateNestedOneWithoutAppointmentsWhereIClientInput;
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  owner: ProfileCreateNestedOneWithoutAppointmentsWhereIOwnerInput;
  status: AppointmentStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AppointmentCreateWithoutOwnerInput = {
  Timeslot: TimeslotCreateNestedOneWithoutAppointmentsInput;
  client: ProfileCreateNestedOneWithoutAppointmentsWhereIClientInput;
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutAppointmentInput>;
  status: AppointmentStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AppointmentCreateWithoutTimeslotInput = {
  client: ProfileCreateNestedOneWithoutAppointmentsWhereIClientInput;
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  notifications?: InputMaybe<NotificationCreateNestedManyWithoutAppointmentInput>;
  owner: ProfileCreateNestedOneWithoutAppointmentsWhereIOwnerInput;
  status: AppointmentStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AppointmentListRelationFilter = {
  every?: InputMaybe<AppointmentWhereInput>;
  none?: InputMaybe<AppointmentWhereInput>;
  some?: InputMaybe<AppointmentWhereInput>;
};

export type AppointmentRelationFilter = {
  is?: InputMaybe<AppointmentWhereInput>;
  isNot?: InputMaybe<AppointmentWhereInput>;
};

export enum AppointmentRole {
  Client = 'CLIENT',
  Owner = 'OWNER'
}

export type AppointmentScalarWhereInput = {
  AND?: InputMaybe<Array<AppointmentScalarWhereInput>>;
  NOT?: InputMaybe<Array<AppointmentScalarWhereInput>>;
  OR?: InputMaybe<Array<AppointmentScalarWhereInput>>;
  clientId?: InputMaybe<IntFilter>;
  comment?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  ownerId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumAppointmentStatusFilter>;
  timeslotId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum AppointmentStatus {
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Pending = 'PENDING'
}

export type AppointmentUpdateManyMutationInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<AppointmentStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AppointmentUpdateManyWithWhereWithoutClientInput = {
  data: AppointmentUpdateManyMutationInput;
  where: AppointmentScalarWhereInput;
};

export type AppointmentUpdateManyWithWhereWithoutOwnerInput = {
  data: AppointmentUpdateManyMutationInput;
  where: AppointmentScalarWhereInput;
};

export type AppointmentUpdateManyWithWhereWithoutTimeslotInput = {
  data: AppointmentUpdateManyMutationInput;
  where: AppointmentScalarWhereInput;
};

export type AppointmentUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AppointmentCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<AppointmentCreateWithoutClientInput>>;
  createMany?: InputMaybe<AppointmentCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  set?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  update?: InputMaybe<Array<AppointmentUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<AppointmentUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<AppointmentUpsertWithWhereUniqueWithoutClientInput>>;
};

export type AppointmentUpdateManyWithoutOwnerNestedInput = {
  connect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AppointmentCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<AppointmentCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<AppointmentCreateManyOwnerInputEnvelope>;
  delete?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  set?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  update?: InputMaybe<Array<AppointmentUpdateWithWhereUniqueWithoutOwnerInput>>;
  updateMany?: InputMaybe<Array<AppointmentUpdateManyWithWhereWithoutOwnerInput>>;
  upsert?: InputMaybe<Array<AppointmentUpsertWithWhereUniqueWithoutOwnerInput>>;
};

export type AppointmentUpdateManyWithoutTimeslotNestedInput = {
  connect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AppointmentCreateOrConnectWithoutTimeslotInput>>;
  create?: InputMaybe<Array<AppointmentCreateWithoutTimeslotInput>>;
  createMany?: InputMaybe<AppointmentCreateManyTimeslotInputEnvelope>;
  delete?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  set?: InputMaybe<Array<AppointmentWhereUniqueInput>>;
  update?: InputMaybe<Array<AppointmentUpdateWithWhereUniqueWithoutTimeslotInput>>;
  updateMany?: InputMaybe<Array<AppointmentUpdateManyWithWhereWithoutTimeslotInput>>;
  upsert?: InputMaybe<Array<AppointmentUpsertWithWhereUniqueWithoutTimeslotInput>>;
};

export type AppointmentUpdateOneRequiredWithoutNotificationsNestedInput = {
  connect?: InputMaybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AppointmentCreateOrConnectWithoutNotificationsInput>;
  create?: InputMaybe<AppointmentCreateWithoutNotificationsInput>;
  update?: InputMaybe<AppointmentUpdateToOneWithWhereWithoutNotificationsInput>;
  upsert?: InputMaybe<AppointmentUpsertWithoutNotificationsInput>;
};

export type AppointmentUpdateToOneWithWhereWithoutNotificationsInput = {
  data: AppointmentUpdateWithoutNotificationsInput;
  where?: InputMaybe<AppointmentWhereInput>;
};

export type AppointmentUpdateWithWhereUniqueWithoutClientInput = {
  data: AppointmentUpdateWithoutClientInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutOwnerInput = {
  data: AppointmentUpdateWithoutOwnerInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutTimeslotInput = {
  data: AppointmentUpdateWithoutTimeslotInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpdateWithoutClientInput = {
  Timeslot?: InputMaybe<TimeslotUpdateOneRequiredWithoutAppointmentsNestedInput>;
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutAppointmentNestedInput>;
  owner?: InputMaybe<ProfileUpdateOneRequiredWithoutAppointmentsWhereIOwnerNestedInput>;
  status?: InputMaybe<AppointmentStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AppointmentUpdateWithoutNotificationsInput = {
  Timeslot?: InputMaybe<TimeslotUpdateOneRequiredWithoutAppointmentsNestedInput>;
  client?: InputMaybe<ProfileUpdateOneRequiredWithoutAppointmentsWhereIClientNestedInput>;
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  owner?: InputMaybe<ProfileUpdateOneRequiredWithoutAppointmentsWhereIOwnerNestedInput>;
  status?: InputMaybe<AppointmentStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AppointmentUpdateWithoutOwnerInput = {
  Timeslot?: InputMaybe<TimeslotUpdateOneRequiredWithoutAppointmentsNestedInput>;
  client?: InputMaybe<ProfileUpdateOneRequiredWithoutAppointmentsWhereIClientNestedInput>;
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutAppointmentNestedInput>;
  status?: InputMaybe<AppointmentStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AppointmentUpdateWithoutTimeslotInput = {
  client?: InputMaybe<ProfileUpdateOneRequiredWithoutAppointmentsWhereIClientNestedInput>;
  comment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  notifications?: InputMaybe<NotificationUpdateManyWithoutAppointmentNestedInput>;
  owner?: InputMaybe<ProfileUpdateOneRequiredWithoutAppointmentsWhereIOwnerNestedInput>;
  status?: InputMaybe<AppointmentStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AppointmentUpsertWithWhereUniqueWithoutClientInput = {
  create: AppointmentCreateWithoutClientInput;
  update: AppointmentUpdateWithoutClientInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutOwnerInput = {
  create: AppointmentCreateWithoutOwnerInput;
  update: AppointmentUpdateWithoutOwnerInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutTimeslotInput = {
  create: AppointmentCreateWithoutTimeslotInput;
  update: AppointmentUpdateWithoutTimeslotInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpsertWithoutNotificationsInput = {
  create: AppointmentCreateWithoutNotificationsInput;
  update: AppointmentUpdateWithoutNotificationsInput;
  where?: InputMaybe<AppointmentWhereInput>;
};

export type AppointmentWhereInput = {
  AND?: InputMaybe<Array<AppointmentWhereInput>>;
  NOT?: InputMaybe<Array<AppointmentWhereInput>>;
  OR?: InputMaybe<Array<AppointmentWhereInput>>;
  Timeslot?: InputMaybe<TimeslotRelationFilter>;
  client?: InputMaybe<ProfileRelationFilter>;
  clientId?: InputMaybe<IntFilter>;
  comment?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  notifications?: InputMaybe<NotificationListRelationFilter>;
  owner?: InputMaybe<ProfileRelationFilter>;
  ownerId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumAppointmentStatusFilter>;
  timeslotId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AppointmentWhereUniqueInput = {
  AND?: InputMaybe<Array<AppointmentWhereInput>>;
  NOT?: InputMaybe<Array<AppointmentWhereInput>>;
  OR?: InputMaybe<Array<AppointmentWhereInput>>;
  Timeslot?: InputMaybe<TimeslotRelationFilter>;
  client?: InputMaybe<ProfileRelationFilter>;
  clientId?: InputMaybe<IntFilter>;
  comment?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  notifications?: InputMaybe<NotificationListRelationFilter>;
  owner?: InputMaybe<ProfileRelationFilter>;
  ownerId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumAppointmentStatusFilter>;
  timeslotId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  account: Account;
  token: Scalars['String']['output'];
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type CalendarEvent = {
  __typename?: 'CalendarEvent';
  attendees?: Maybe<Array<EventAttendee>>;
  description: Scalars['String']['output'];
  end: EventDateTime;
  id: Scalars['String']['output'];
  location: Scalars['String']['output'];
  start: EventDateTime;
  summary: Scalars['String']['output'];
};

export type CalendarEventInput = {
  attendees?: InputMaybe<Array<EventAttendeeInput>>;
  description: Scalars['String']['input'];
  end: EventDateTimeInput;
  location: Scalars['String']['input'];
  start: EventDateTimeInput;
  summary: Scalars['String']['input'];
};

export type CreateAppointmentInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  sendNotificationAt?: InputMaybe<Scalars['DateTime']['input']>;
  timeslotId: Scalars['Float']['input'];
};

export type CreateTimetableInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['DateTime']['input'];
  slotDurationInMs: Scalars['Float']['input'];
  startDate: Scalars['DateTime']['input'];
  title: Scalars['String']['input'];
  workingDays?: InputMaybe<Array<WorkingDayInput>>;
};

export type CustomDateInput = {
  date: Scalars['DateTime']['input'];
  slotDurationInMs: Scalars['Float']['input'];
  timeRange: TimeRangeInput;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type EmailPasswordInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type EnumAccountStatusFilter = {
  equals?: InputMaybe<AccountStatus>;
  in?: InputMaybe<Array<AccountStatus>>;
  not?: InputMaybe<NestedEnumAccountStatusFilter>;
  notIn?: InputMaybe<Array<AccountStatus>>;
};

export type EnumAppointmentStatusFilter = {
  equals?: InputMaybe<AppointmentStatus>;
  in?: InputMaybe<Array<AppointmentStatus>>;
  not?: InputMaybe<NestedEnumAppointmentStatusFilter>;
  notIn?: InputMaybe<Array<AppointmentStatus>>;
};

export type EnumProfileRoleNullableListFilter = {
  equals?: InputMaybe<Array<ProfileRole>>;
  has?: InputMaybe<ProfileRole>;
  hasEvery?: InputMaybe<Array<ProfileRole>>;
  hasSome?: InputMaybe<Array<ProfileRole>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EventAttendee = {
  __typename?: 'EventAttendee';
  email: Scalars['String']['output'];
};

export type EventAttendeeInput = {
  email: Scalars['String']['input'];
};

export type EventDateTime = {
  __typename?: 'EventDateTime';
  dateTime: Scalars['String']['output'];
  timeZone: Scalars['String']['output'];
};

export type EventDateTimeInput = {
  dateTime: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
};

export type GoogleSession = {
  __typename?: 'GoogleSession';
  account: Account;
  accountId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  expiryDate: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GoogleSessionCreateManyAccountInput = {
  accessToken: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiryDate: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  refreshToken: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GoogleSessionCreateManyAccountInputEnvelope = {
  data: Array<GoogleSessionCreateManyAccountInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GoogleSessionCreateNestedManyWithoutAccountInput = {
  connect?: InputMaybe<Array<GoogleSessionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GoogleSessionCreateOrConnectWithoutAccountInput>>;
  create?: InputMaybe<Array<GoogleSessionCreateWithoutAccountInput>>;
  createMany?: InputMaybe<GoogleSessionCreateManyAccountInputEnvelope>;
};

export type GoogleSessionCreateOrConnectWithoutAccountInput = {
  create: GoogleSessionCreateWithoutAccountInput;
  where: GoogleSessionWhereUniqueInput;
};

export type GoogleSessionCreateWithoutAccountInput = {
  accessToken: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiryDate: Scalars['DateTime']['input'];
  refreshToken: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GoogleSessionListRelationFilter = {
  every?: InputMaybe<GoogleSessionWhereInput>;
  none?: InputMaybe<GoogleSessionWhereInput>;
  some?: InputMaybe<GoogleSessionWhereInput>;
};

export type GoogleSessionScalarWhereInput = {
  AND?: InputMaybe<Array<GoogleSessionScalarWhereInput>>;
  NOT?: InputMaybe<Array<GoogleSessionScalarWhereInput>>;
  OR?: InputMaybe<Array<GoogleSessionScalarWhereInput>>;
  accessToken?: InputMaybe<StringFilter>;
  accountId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiryDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  refreshToken?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type GoogleSessionUpdateManyMutationInput = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GoogleSessionUpdateManyWithWhereWithoutAccountInput = {
  data: GoogleSessionUpdateManyMutationInput;
  where: GoogleSessionScalarWhereInput;
};

export type GoogleSessionUpdateManyWithoutAccountNestedInput = {
  connect?: InputMaybe<Array<GoogleSessionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GoogleSessionCreateOrConnectWithoutAccountInput>>;
  create?: InputMaybe<Array<GoogleSessionCreateWithoutAccountInput>>;
  createMany?: InputMaybe<GoogleSessionCreateManyAccountInputEnvelope>;
  delete?: InputMaybe<Array<GoogleSessionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<GoogleSessionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<GoogleSessionWhereUniqueInput>>;
  set?: InputMaybe<Array<GoogleSessionWhereUniqueInput>>;
  update?: InputMaybe<Array<GoogleSessionUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: InputMaybe<Array<GoogleSessionUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: InputMaybe<Array<GoogleSessionUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type GoogleSessionUpdateWithWhereUniqueWithoutAccountInput = {
  data: GoogleSessionUpdateWithoutAccountInput;
  where: GoogleSessionWhereUniqueInput;
};

export type GoogleSessionUpdateWithoutAccountInput = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiryDate?: InputMaybe<Scalars['DateTime']['input']>;
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GoogleSessionUpsertWithWhereUniqueWithoutAccountInput = {
  create: GoogleSessionCreateWithoutAccountInput;
  update: GoogleSessionUpdateWithoutAccountInput;
  where: GoogleSessionWhereUniqueInput;
};

export type GoogleSessionWhereInput = {
  AND?: InputMaybe<Array<GoogleSessionWhereInput>>;
  NOT?: InputMaybe<Array<GoogleSessionWhereInput>>;
  OR?: InputMaybe<Array<GoogleSessionWhereInput>>;
  accessToken?: InputMaybe<StringFilter>;
  account?: InputMaybe<AccountRelationFilter>;
  accountId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiryDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  refreshToken?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type GoogleSessionWhereUniqueInput = {
  AND?: InputMaybe<Array<GoogleSessionWhereInput>>;
  NOT?: InputMaybe<Array<GoogleSessionWhereInput>>;
  OR?: InputMaybe<Array<GoogleSessionWhereInput>>;
  accessToken?: InputMaybe<Scalars['String']['input']>;
  account?: InputMaybe<AccountRelationFilter>;
  accountId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiryDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  refreshToken?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type LoginViaTelegramInput = {
  initLoadData: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addCalendarEvent: CalendarEvent;
  addNotificationsToAppointment: Array<Notification>;
  approveAppointment: Appointment;
  bookAppointment: Appointment;
  cancelAppointment: Appointment;
  changePassword: Account;
  changeTotpEnabled: Scalars['Boolean']['output'];
  clearTimetableCache: Scalars['Boolean']['output'];
  createPreviewTimetable: Timetable;
  createTimeslotsForWorkingDay: Array<Timeslot>;
  createTimetable: Timetable;
  deleteCalendarEvent: Scalars['Boolean']['output'];
  deleteTimeslot: Scalars['Boolean']['output'];
  deleteTimeslotsForDay: Scalars['Boolean']['output'];
  deleteTimetable: Timetable;
  disableNotifications: Scalars['Float']['output'];
  echo: Scalars['String']['output'];
  login: AuthResponse;
  loginAs: AuthResponse;
  loginViaTelegram: AuthResponse;
  loginWithGoogle: AuthResponse;
  logout: Scalars['Boolean']['output'];
  register: AuthResponse;
  updateAppointment: Appointment;
  updateCalendarEvent: CalendarEvent;
  updateProfile: Profile;
};


export type MutationAddCalendarEventArgs = {
  event: CalendarEventInput;
};


export type MutationAddNotificationsToAppointmentArgs = {
  addNotificationsJobInput: AddNotificationJobInput;
};


export type MutationApproveAppointmentArgs = {
  appointmentId: Scalars['Float']['input'];
};


export type MutationBookAppointmentArgs = {
  createAppointmentInput: CreateAppointmentInput;
};


export type MutationCancelAppointmentArgs = {
  appointmentId: Scalars['Float']['input'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationChangeTotpEnabledArgs = {
  enabled: Scalars['Boolean']['input'];
  token: Scalars['String']['input'];
};


export type MutationClearTimetableCacheArgs = {
  ownerId: Scalars['Float']['input'];
};


export type MutationCreatePreviewTimetableArgs = {
  data: CreateTimetableInput;
};


export type MutationCreateTimeslotsForWorkingDayArgs = {
  data: CustomDateInput;
};


export type MutationCreateTimetableArgs = {
  data: CreateTimetableInput;
};


export type MutationDeleteCalendarEventArgs = {
  eventId: Scalars['String']['input'];
};


export type MutationDeleteTimeslotArgs = {
  timeslotId: Scalars['Float']['input'];
};


export type MutationDeleteTimeslotsForDayArgs = {
  date: Scalars['String']['input'];
  timetableId: Scalars['Float']['input'];
};


export type MutationDeleteTimetableArgs = {
  timetableId: Scalars['Float']['input'];
};


export type MutationDisableNotificationsArgs = {
  appointmentId: Scalars['Float']['input'];
};


export type MutationEchoArgs = {
  text: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  data: EmailPasswordInput;
  totpToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationLoginAsArgs = {
  email: Scalars['String']['input'];
};


export type MutationLoginViaTelegramArgs = {
  data: LoginViaTelegramInput;
};


export type MutationLoginWithGoogleArgs = {
  code: Scalars['String']['input'];
};


export type MutationLogoutArgs = {
  sessionIds: Array<Scalars['Float']['input']>;
};


export type MutationRegisterArgs = {
  data: EmailPasswordInput;
};


export type MutationUpdateAppointmentArgs = {
  updateAppointmentInput: UpdateAppointmentInput;
};


export type MutationUpdateCalendarEventArgs = {
  event: CalendarEventInput;
  eventId: Scalars['String']['input'];
};


export type MutationUpdateProfileArgs = {
  input: ProfileUpdateInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedEnumAccountStatusFilter = {
  equals?: InputMaybe<AccountStatus>;
  in?: InputMaybe<Array<AccountStatus>>;
  not?: InputMaybe<NestedEnumAccountStatusFilter>;
  notIn?: InputMaybe<Array<AccountStatus>>;
};

export type NestedEnumAppointmentStatusFilter = {
  equals?: InputMaybe<AppointmentStatus>;
  in?: InputMaybe<Array<AppointmentStatus>>;
  not?: InputMaybe<NestedEnumAppointmentStatusFilter>;
  notIn?: InputMaybe<Array<AppointmentStatus>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Notification = {
  __typename?: 'Notification';
  appointment: Appointment;
  appointmentId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  jobId: Scalars['Int']['output'];
  recipient: Profile;
  recipientId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type NotificationCreateManyAppointmentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  jobId: Scalars['Int']['input'];
  recipientId: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type NotificationCreateManyAppointmentInputEnvelope = {
  data: Array<NotificationCreateManyAppointmentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NotificationCreateManyRecipientInput = {
  appointmentId: Scalars['Int']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  jobId: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type NotificationCreateManyRecipientInputEnvelope = {
  data: Array<NotificationCreateManyRecipientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NotificationCreateNestedManyWithoutAppointmentInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutAppointmentInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutAppointmentInput>>;
  createMany?: InputMaybe<NotificationCreateManyAppointmentInputEnvelope>;
};

export type NotificationCreateNestedManyWithoutRecipientInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutRecipientInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutRecipientInput>>;
  createMany?: InputMaybe<NotificationCreateManyRecipientInputEnvelope>;
};

export type NotificationCreateOrConnectWithoutAppointmentInput = {
  create: NotificationCreateWithoutAppointmentInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationCreateOrConnectWithoutRecipientInput = {
  create: NotificationCreateWithoutRecipientInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationCreateWithoutAppointmentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  jobId: Scalars['Int']['input'];
  recipient: ProfileCreateNestedOneWithoutNotificationInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type NotificationCreateWithoutRecipientInput = {
  appointment: AppointmentCreateNestedOneWithoutNotificationsInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  jobId: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type NotificationInput = {
  sendNotificationAt: Scalars['DateTime']['input'];
};

export type NotificationListRelationFilter = {
  every?: InputMaybe<NotificationWhereInput>;
  none?: InputMaybe<NotificationWhereInput>;
  some?: InputMaybe<NotificationWhereInput>;
};

export type NotificationScalarWhereInput = {
  AND?: InputMaybe<Array<NotificationScalarWhereInput>>;
  NOT?: InputMaybe<Array<NotificationScalarWhereInput>>;
  OR?: InputMaybe<Array<NotificationScalarWhereInput>>;
  appointmentId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  jobId?: InputMaybe<IntFilter>;
  recipientId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type NotificationUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type NotificationUpdateManyWithWhereWithoutAppointmentInput = {
  data: NotificationUpdateManyMutationInput;
  where: NotificationScalarWhereInput;
};

export type NotificationUpdateManyWithWhereWithoutRecipientInput = {
  data: NotificationUpdateManyMutationInput;
  where: NotificationScalarWhereInput;
};

export type NotificationUpdateManyWithoutAppointmentNestedInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutAppointmentInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutAppointmentInput>>;
  createMany?: InputMaybe<NotificationCreateManyAppointmentInputEnvelope>;
  delete?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NotificationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  set?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  update?: InputMaybe<Array<NotificationUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: InputMaybe<Array<NotificationUpdateManyWithWhereWithoutAppointmentInput>>;
  upsert?: InputMaybe<Array<NotificationUpsertWithWhereUniqueWithoutAppointmentInput>>;
};

export type NotificationUpdateManyWithoutRecipientNestedInput = {
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationCreateOrConnectWithoutRecipientInput>>;
  create?: InputMaybe<Array<NotificationCreateWithoutRecipientInput>>;
  createMany?: InputMaybe<NotificationCreateManyRecipientInputEnvelope>;
  delete?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NotificationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  set?: InputMaybe<Array<NotificationWhereUniqueInput>>;
  update?: InputMaybe<Array<NotificationUpdateWithWhereUniqueWithoutRecipientInput>>;
  updateMany?: InputMaybe<Array<NotificationUpdateManyWithWhereWithoutRecipientInput>>;
  upsert?: InputMaybe<Array<NotificationUpsertWithWhereUniqueWithoutRecipientInput>>;
};

export type NotificationUpdateWithWhereUniqueWithoutAppointmentInput = {
  data: NotificationUpdateWithoutAppointmentInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpdateWithWhereUniqueWithoutRecipientInput = {
  data: NotificationUpdateWithoutRecipientInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpdateWithoutAppointmentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  recipient?: InputMaybe<ProfileUpdateOneRequiredWithoutNotificationNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type NotificationUpdateWithoutRecipientInput = {
  appointment?: InputMaybe<AppointmentUpdateOneRequiredWithoutNotificationsNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type NotificationUpsertWithWhereUniqueWithoutAppointmentInput = {
  create: NotificationCreateWithoutAppointmentInput;
  update: NotificationUpdateWithoutAppointmentInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpsertWithWhereUniqueWithoutRecipientInput = {
  create: NotificationCreateWithoutRecipientInput;
  update: NotificationUpdateWithoutRecipientInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationWhereInput = {
  AND?: InputMaybe<Array<NotificationWhereInput>>;
  NOT?: InputMaybe<Array<NotificationWhereInput>>;
  OR?: InputMaybe<Array<NotificationWhereInput>>;
  appointment?: InputMaybe<AppointmentRelationFilter>;
  appointmentId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  jobId?: InputMaybe<IntFilter>;
  recipient?: InputMaybe<ProfileRelationFilter>;
  recipientId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type NotificationWhereUniqueInput = {
  AND?: InputMaybe<Array<NotificationWhereInput>>;
  NOT?: InputMaybe<Array<NotificationWhereInput>>;
  OR?: InputMaybe<Array<NotificationWhereInput>>;
  appointment?: InputMaybe<AppointmentRelationFilter>;
  appointmentId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  jobId?: InputMaybe<IntFilter>;
  recipient?: InputMaybe<ProfileRelationFilter>;
  recipientId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Profile = {
  __typename?: 'Profile';
  Notification?: Maybe<Array<Notification>>;
  Timetable?: Maybe<Array<Timetable>>;
  _count: ProfileCount;
  accounts?: Maybe<Array<Account>>;
  allowsWriteToPm: Scalars['Boolean']['output'];
  appointmentsWhereIClient?: Maybe<Array<Appointment>>;
  appointmentsWhereIOwner?: Maybe<Array<Appointment>>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  languageCode?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<ProfileRole>>;
  status: AccountStatus;
  timeslots?: Maybe<Array<Timeslot>>;
  totpEnabled: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

export type ProfileCount = {
  __typename?: 'ProfileCount';
  Notification: Scalars['Int']['output'];
  Timetable: Scalars['Int']['output'];
  accounts: Scalars['Int']['output'];
  appointmentsWhereIClient: Scalars['Int']['output'];
  appointmentsWhereIOwner: Scalars['Int']['output'];
  timeslots: Scalars['Int']['output'];
};

export type ProfileCreateNestedOneWithoutAppointmentsWhereIClientInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutAppointmentsWhereIClientInput>;
  create?: InputMaybe<ProfileCreateWithoutAppointmentsWhereIClientInput>;
};

export type ProfileCreateNestedOneWithoutAppointmentsWhereIOwnerInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutAppointmentsWhereIOwnerInput>;
  create?: InputMaybe<ProfileCreateWithoutAppointmentsWhereIOwnerInput>;
};

export type ProfileCreateNestedOneWithoutNotificationInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutNotificationInput>;
  create?: InputMaybe<ProfileCreateWithoutNotificationInput>;
};

export type ProfileCreateNestedOneWithoutTimeslotsInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutTimeslotsInput>;
  create?: InputMaybe<ProfileCreateWithoutTimeslotsInput>;
};

export type ProfileCreateNestedOneWithoutTimetableInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutTimetableInput>;
  create?: InputMaybe<ProfileCreateWithoutTimetableInput>;
};

export type ProfileCreateOrConnectWithoutAppointmentsWhereIClientInput = {
  create: ProfileCreateWithoutAppointmentsWhereIClientInput;
  where: ProfileWhereUniqueInput;
};

export type ProfileCreateOrConnectWithoutAppointmentsWhereIOwnerInput = {
  create: ProfileCreateWithoutAppointmentsWhereIOwnerInput;
  where: ProfileWhereUniqueInput;
};

export type ProfileCreateOrConnectWithoutNotificationInput = {
  create: ProfileCreateWithoutNotificationInput;
  where: ProfileWhereUniqueInput;
};

export type ProfileCreateOrConnectWithoutTimeslotsInput = {
  create: ProfileCreateWithoutTimeslotsInput;
  where: ProfileWhereUniqueInput;
};

export type ProfileCreateOrConnectWithoutTimetableInput = {
  create: ProfileCreateWithoutTimetableInput;
  where: ProfileWhereUniqueInput;
};

export type ProfileCreateWithoutAppointmentsWhereIClientInput = {
  Notification?: InputMaybe<NotificationCreateNestedManyWithoutRecipientInput>;
  Timetable?: InputMaybe<TimetableCreateNestedManyWithoutOwnerInput>;
  accounts?: InputMaybe<AccountCreateNestedManyWithoutProfileInput>;
  allowsWriteToPm?: InputMaybe<Scalars['Boolean']['input']>;
  appointmentsWhereIOwner?: InputMaybe<AppointmentCreateNestedManyWithoutOwnerInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  status: AccountStatus;
  timeslots?: InputMaybe<TimeslotCreateNestedManyWithoutOwnerInput>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileCreateWithoutAppointmentsWhereIOwnerInput = {
  Notification?: InputMaybe<NotificationCreateNestedManyWithoutRecipientInput>;
  Timetable?: InputMaybe<TimetableCreateNestedManyWithoutOwnerInput>;
  accounts?: InputMaybe<AccountCreateNestedManyWithoutProfileInput>;
  allowsWriteToPm?: InputMaybe<Scalars['Boolean']['input']>;
  appointmentsWhereIClient?: InputMaybe<AppointmentCreateNestedManyWithoutClientInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  status: AccountStatus;
  timeslots?: InputMaybe<TimeslotCreateNestedManyWithoutOwnerInput>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileCreateWithoutNotificationInput = {
  Timetable?: InputMaybe<TimetableCreateNestedManyWithoutOwnerInput>;
  accounts?: InputMaybe<AccountCreateNestedManyWithoutProfileInput>;
  allowsWriteToPm?: InputMaybe<Scalars['Boolean']['input']>;
  appointmentsWhereIClient?: InputMaybe<AppointmentCreateNestedManyWithoutClientInput>;
  appointmentsWhereIOwner?: InputMaybe<AppointmentCreateNestedManyWithoutOwnerInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  status: AccountStatus;
  timeslots?: InputMaybe<TimeslotCreateNestedManyWithoutOwnerInput>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileCreateWithoutTimeslotsInput = {
  Notification?: InputMaybe<NotificationCreateNestedManyWithoutRecipientInput>;
  Timetable?: InputMaybe<TimetableCreateNestedManyWithoutOwnerInput>;
  accounts?: InputMaybe<AccountCreateNestedManyWithoutProfileInput>;
  allowsWriteToPm?: InputMaybe<Scalars['Boolean']['input']>;
  appointmentsWhereIClient?: InputMaybe<AppointmentCreateNestedManyWithoutClientInput>;
  appointmentsWhereIOwner?: InputMaybe<AppointmentCreateNestedManyWithoutOwnerInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  status: AccountStatus;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileCreateWithoutTimetableInput = {
  Notification?: InputMaybe<NotificationCreateNestedManyWithoutRecipientInput>;
  accounts?: InputMaybe<AccountCreateNestedManyWithoutProfileInput>;
  allowsWriteToPm?: InputMaybe<Scalars['Boolean']['input']>;
  appointmentsWhereIClient?: InputMaybe<AppointmentCreateNestedManyWithoutClientInput>;
  appointmentsWhereIOwner?: InputMaybe<AppointmentCreateNestedManyWithoutOwnerInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  status: AccountStatus;
  timeslots?: InputMaybe<TimeslotCreateNestedManyWithoutOwnerInput>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileNullableRelationFilter = {
  is?: InputMaybe<ProfileWhereInput>;
  isNot?: InputMaybe<ProfileWhereInput>;
};

export type ProfileRelationFilter = {
  is?: InputMaybe<ProfileWhereInput>;
  isNot?: InputMaybe<ProfileWhereInput>;
};

export enum ProfileRole {
  Admin = 'ADMIN',
  User = 'USER'
}

export type ProfileUpdateInput = {
  Notification?: InputMaybe<NotificationUpdateManyWithoutRecipientNestedInput>;
  Timetable?: InputMaybe<TimetableUpdateManyWithoutOwnerNestedInput>;
  accounts?: InputMaybe<AccountUpdateManyWithoutProfileNestedInput>;
  allowsWriteToPm?: InputMaybe<Scalars['Boolean']['input']>;
  appointmentsWhereIClient?: InputMaybe<AppointmentUpdateManyWithoutClientNestedInput>;
  appointmentsWhereIOwner?: InputMaybe<AppointmentUpdateManyWithoutOwnerNestedInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  status?: InputMaybe<AccountStatus>;
  timeslots?: InputMaybe<TimeslotUpdateManyWithoutOwnerNestedInput>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileUpdateOneRequiredWithoutAppointmentsWhereIClientNestedInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutAppointmentsWhereIClientInput>;
  create?: InputMaybe<ProfileCreateWithoutAppointmentsWhereIClientInput>;
  update?: InputMaybe<ProfileUpdateToOneWithWhereWithoutAppointmentsWhereIClientInput>;
  upsert?: InputMaybe<ProfileUpsertWithoutAppointmentsWhereIClientInput>;
};

export type ProfileUpdateOneRequiredWithoutAppointmentsWhereIOwnerNestedInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutAppointmentsWhereIOwnerInput>;
  create?: InputMaybe<ProfileCreateWithoutAppointmentsWhereIOwnerInput>;
  update?: InputMaybe<ProfileUpdateToOneWithWhereWithoutAppointmentsWhereIOwnerInput>;
  upsert?: InputMaybe<ProfileUpsertWithoutAppointmentsWhereIOwnerInput>;
};

export type ProfileUpdateOneRequiredWithoutNotificationNestedInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutNotificationInput>;
  create?: InputMaybe<ProfileCreateWithoutNotificationInput>;
  update?: InputMaybe<ProfileUpdateToOneWithWhereWithoutNotificationInput>;
  upsert?: InputMaybe<ProfileUpsertWithoutNotificationInput>;
};

export type ProfileUpdateOneRequiredWithoutTimeslotsNestedInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutTimeslotsInput>;
  create?: InputMaybe<ProfileCreateWithoutTimeslotsInput>;
  update?: InputMaybe<ProfileUpdateToOneWithWhereWithoutTimeslotsInput>;
  upsert?: InputMaybe<ProfileUpsertWithoutTimeslotsInput>;
};

export type ProfileUpdateOneRequiredWithoutTimetableNestedInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutTimetableInput>;
  create?: InputMaybe<ProfileCreateWithoutTimetableInput>;
  update?: InputMaybe<ProfileUpdateToOneWithWhereWithoutTimetableInput>;
  upsert?: InputMaybe<ProfileUpsertWithoutTimetableInput>;
};

export type ProfileUpdateToOneWithWhereWithoutAppointmentsWhereIClientInput = {
  data: ProfileUpdateWithoutAppointmentsWhereIClientInput;
  where?: InputMaybe<ProfileWhereInput>;
};

export type ProfileUpdateToOneWithWhereWithoutAppointmentsWhereIOwnerInput = {
  data: ProfileUpdateWithoutAppointmentsWhereIOwnerInput;
  where?: InputMaybe<ProfileWhereInput>;
};

export type ProfileUpdateToOneWithWhereWithoutNotificationInput = {
  data: ProfileUpdateWithoutNotificationInput;
  where?: InputMaybe<ProfileWhereInput>;
};

export type ProfileUpdateToOneWithWhereWithoutTimeslotsInput = {
  data: ProfileUpdateWithoutTimeslotsInput;
  where?: InputMaybe<ProfileWhereInput>;
};

export type ProfileUpdateToOneWithWhereWithoutTimetableInput = {
  data: ProfileUpdateWithoutTimetableInput;
  where?: InputMaybe<ProfileWhereInput>;
};

export type ProfileUpdateWithoutAppointmentsWhereIClientInput = {
  Notification?: InputMaybe<NotificationUpdateManyWithoutRecipientNestedInput>;
  Timetable?: InputMaybe<TimetableUpdateManyWithoutOwnerNestedInput>;
  accounts?: InputMaybe<AccountUpdateManyWithoutProfileNestedInput>;
  allowsWriteToPm?: InputMaybe<Scalars['Boolean']['input']>;
  appointmentsWhereIOwner?: InputMaybe<AppointmentUpdateManyWithoutOwnerNestedInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  status?: InputMaybe<AccountStatus>;
  timeslots?: InputMaybe<TimeslotUpdateManyWithoutOwnerNestedInput>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileUpdateWithoutAppointmentsWhereIOwnerInput = {
  Notification?: InputMaybe<NotificationUpdateManyWithoutRecipientNestedInput>;
  Timetable?: InputMaybe<TimetableUpdateManyWithoutOwnerNestedInput>;
  accounts?: InputMaybe<AccountUpdateManyWithoutProfileNestedInput>;
  allowsWriteToPm?: InputMaybe<Scalars['Boolean']['input']>;
  appointmentsWhereIClient?: InputMaybe<AppointmentUpdateManyWithoutClientNestedInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  status?: InputMaybe<AccountStatus>;
  timeslots?: InputMaybe<TimeslotUpdateManyWithoutOwnerNestedInput>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileUpdateWithoutNotificationInput = {
  Timetable?: InputMaybe<TimetableUpdateManyWithoutOwnerNestedInput>;
  accounts?: InputMaybe<AccountUpdateManyWithoutProfileNestedInput>;
  allowsWriteToPm?: InputMaybe<Scalars['Boolean']['input']>;
  appointmentsWhereIClient?: InputMaybe<AppointmentUpdateManyWithoutClientNestedInput>;
  appointmentsWhereIOwner?: InputMaybe<AppointmentUpdateManyWithoutOwnerNestedInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  status?: InputMaybe<AccountStatus>;
  timeslots?: InputMaybe<TimeslotUpdateManyWithoutOwnerNestedInput>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileUpdateWithoutTimeslotsInput = {
  Notification?: InputMaybe<NotificationUpdateManyWithoutRecipientNestedInput>;
  Timetable?: InputMaybe<TimetableUpdateManyWithoutOwnerNestedInput>;
  accounts?: InputMaybe<AccountUpdateManyWithoutProfileNestedInput>;
  allowsWriteToPm?: InputMaybe<Scalars['Boolean']['input']>;
  appointmentsWhereIClient?: InputMaybe<AppointmentUpdateManyWithoutClientNestedInput>;
  appointmentsWhereIOwner?: InputMaybe<AppointmentUpdateManyWithoutOwnerNestedInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  status?: InputMaybe<AccountStatus>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileUpdateWithoutTimetableInput = {
  Notification?: InputMaybe<NotificationUpdateManyWithoutRecipientNestedInput>;
  accounts?: InputMaybe<AccountUpdateManyWithoutProfileNestedInput>;
  allowsWriteToPm?: InputMaybe<Scalars['Boolean']['input']>;
  appointmentsWhereIClient?: InputMaybe<AppointmentUpdateManyWithoutClientNestedInput>;
  appointmentsWhereIOwner?: InputMaybe<AppointmentUpdateManyWithoutOwnerNestedInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  status?: InputMaybe<AccountStatus>;
  timeslots?: InputMaybe<TimeslotUpdateManyWithoutOwnerNestedInput>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileUpsertWithoutAppointmentsWhereIClientInput = {
  create: ProfileCreateWithoutAppointmentsWhereIClientInput;
  update: ProfileUpdateWithoutAppointmentsWhereIClientInput;
  where?: InputMaybe<ProfileWhereInput>;
};

export type ProfileUpsertWithoutAppointmentsWhereIOwnerInput = {
  create: ProfileCreateWithoutAppointmentsWhereIOwnerInput;
  update: ProfileUpdateWithoutAppointmentsWhereIOwnerInput;
  where?: InputMaybe<ProfileWhereInput>;
};

export type ProfileUpsertWithoutNotificationInput = {
  create: ProfileCreateWithoutNotificationInput;
  update: ProfileUpdateWithoutNotificationInput;
  where?: InputMaybe<ProfileWhereInput>;
};

export type ProfileUpsertWithoutTimeslotsInput = {
  create: ProfileCreateWithoutTimeslotsInput;
  update: ProfileUpdateWithoutTimeslotsInput;
  where?: InputMaybe<ProfileWhereInput>;
};

export type ProfileUpsertWithoutTimetableInput = {
  create: ProfileCreateWithoutTimetableInput;
  update: ProfileUpdateWithoutTimetableInput;
  where?: InputMaybe<ProfileWhereInput>;
};

export type ProfileWhereInput = {
  AND?: InputMaybe<Array<ProfileWhereInput>>;
  NOT?: InputMaybe<Array<ProfileWhereInput>>;
  Notification?: InputMaybe<NotificationListRelationFilter>;
  OR?: InputMaybe<Array<ProfileWhereInput>>;
  Timetable?: InputMaybe<TimetableListRelationFilter>;
  accounts?: InputMaybe<AccountListRelationFilter>;
  allowsWriteToPm?: InputMaybe<BoolFilter>;
  appointmentsWhereIClient?: InputMaybe<AppointmentListRelationFilter>;
  appointmentsWhereIOwner?: InputMaybe<AppointmentListRelationFilter>;
  avatarUrl?: InputMaybe<StringNullableFilter>;
  bio?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  languageCode?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  roles?: InputMaybe<EnumProfileRoleNullableListFilter>;
  status?: InputMaybe<EnumAccountStatusFilter>;
  timeslots?: InputMaybe<TimeslotListRelationFilter>;
  totpEnabled?: InputMaybe<BoolFilter>;
  totpSecret?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  username?: InputMaybe<StringNullableFilter>;
};

export type ProfileWhereUniqueInput = {
  AND?: InputMaybe<Array<ProfileWhereInput>>;
  NOT?: InputMaybe<Array<ProfileWhereInput>>;
  Notification?: InputMaybe<NotificationListRelationFilter>;
  OR?: InputMaybe<Array<ProfileWhereInput>>;
  Timetable?: InputMaybe<TimetableListRelationFilter>;
  accounts?: InputMaybe<AccountListRelationFilter>;
  allowsWriteToPm?: InputMaybe<BoolFilter>;
  appointmentsWhereIClient?: InputMaybe<AppointmentListRelationFilter>;
  appointmentsWhereIOwner?: InputMaybe<AppointmentListRelationFilter>;
  avatarUrl?: InputMaybe<StringNullableFilter>;
  bio?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  languageCode?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  roles?: InputMaybe<EnumProfileRoleNullableListFilter>;
  status?: InputMaybe<EnumAccountStatusFilter>;
  timeslots?: InputMaybe<TimeslotListRelationFilter>;
  totpEnabled?: InputMaybe<BoolFilter>;
  totpSecret?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  username?: InputMaybe<StringNullableFilter>;
};

export type Query = {
  __typename?: 'Query';
  currentSession: AccountSession;
  debug: Scalars['JSON']['output'];
  echo: Scalars['String']['output'];
  generateAuthUrl: Scalars['String']['output'];
  generateTotpSecret: Scalars['String']['output'];
  getAppointment: Appointment;
  getAppointments: Array<Appointment>;
  getCalendarEvents: Array<CalendarEvent>;
  getTimeslotsForDay: Array<Timeslot>;
  getTimetable: Timetable;
  hasTimetable?: Maybe<Timetable>;
  testTranslation: Scalars['String']['output'];
  whoami: Account;
};


export type QueryEchoArgs = {
  text: Scalars['String']['input'];
};


export type QueryGenerateAuthUrlArgs = {
  state?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGenerateTotpSecretArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAppointmentArgs = {
  appointmentId: Scalars['Float']['input'];
};


export type QueryGetTimeslotsForDayArgs = {
  date: Scalars['String']['input'];
  timetableId: Scalars['Float']['input'];
};


export type QueryGetTimetableArgs = {
  ownerId: Scalars['Float']['input'];
};


export type QueryTestTranslationArgs = {
  username: Scalars['String']['input'];
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type TimeRangeInput = {
  endMinutes: Scalars['Float']['input'];
  endTimeInHours: Scalars['Float']['input'];
  startMinutes: Scalars['Float']['input'];
  startTimeInHours: Scalars['Float']['input'];
};

export type Timeslot = {
  __typename?: 'Timeslot';
  _count: TimeslotCount;
  appointment?: Maybe<Appointment>;
  appointments?: Maybe<Array<Appointment>>;
  createdAt: Scalars['DateTime']['output'];
  endAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  owner: Profile;
  ownerId: Scalars['Int']['output'];
  startAt: Scalars['DateTime']['output'];
  timetable: Timetable;
  timetableId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TimeslotCount = {
  __typename?: 'TimeslotCount';
  appointments: Scalars['Int']['output'];
};

export type TimeslotCreateManyOwnerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  endAt: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  startAt: Scalars['DateTime']['input'];
  timetableId: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimeslotCreateManyOwnerInputEnvelope = {
  data: Array<TimeslotCreateManyOwnerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TimeslotCreateManyTimetableInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  endAt: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  ownerId: Scalars['Int']['input'];
  startAt: Scalars['DateTime']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimeslotCreateManyTimetableInputEnvelope = {
  data: Array<TimeslotCreateManyTimetableInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TimeslotCreateNestedManyWithoutOwnerInput = {
  connect?: InputMaybe<Array<TimeslotWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TimeslotCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<TimeslotCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<TimeslotCreateManyOwnerInputEnvelope>;
};

export type TimeslotCreateNestedManyWithoutTimetableInput = {
  connect?: InputMaybe<Array<TimeslotWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TimeslotCreateOrConnectWithoutTimetableInput>>;
  create?: InputMaybe<Array<TimeslotCreateWithoutTimetableInput>>;
  createMany?: InputMaybe<TimeslotCreateManyTimetableInputEnvelope>;
};

export type TimeslotCreateNestedOneWithoutAppointmentsInput = {
  connect?: InputMaybe<TimeslotWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TimeslotCreateOrConnectWithoutAppointmentsInput>;
  create?: InputMaybe<TimeslotCreateWithoutAppointmentsInput>;
};

export type TimeslotCreateOrConnectWithoutAppointmentsInput = {
  create: TimeslotCreateWithoutAppointmentsInput;
  where: TimeslotWhereUniqueInput;
};

export type TimeslotCreateOrConnectWithoutOwnerInput = {
  create: TimeslotCreateWithoutOwnerInput;
  where: TimeslotWhereUniqueInput;
};

export type TimeslotCreateOrConnectWithoutTimetableInput = {
  create: TimeslotCreateWithoutTimetableInput;
  where: TimeslotWhereUniqueInput;
};

export type TimeslotCreateWithoutAppointmentsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  endAt: Scalars['DateTime']['input'];
  owner: ProfileCreateNestedOneWithoutTimeslotsInput;
  startAt: Scalars['DateTime']['input'];
  timetable: TimetableCreateNestedOneWithoutTimeslotsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimeslotCreateWithoutOwnerInput = {
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutTimeslotInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  endAt: Scalars['DateTime']['input'];
  startAt: Scalars['DateTime']['input'];
  timetable: TimetableCreateNestedOneWithoutTimeslotsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimeslotCreateWithoutTimetableInput = {
  appointments?: InputMaybe<AppointmentCreateNestedManyWithoutTimeslotInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  endAt: Scalars['DateTime']['input'];
  owner: ProfileCreateNestedOneWithoutTimeslotsInput;
  startAt: Scalars['DateTime']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimeslotListRelationFilter = {
  every?: InputMaybe<TimeslotWhereInput>;
  none?: InputMaybe<TimeslotWhereInput>;
  some?: InputMaybe<TimeslotWhereInput>;
};

export type TimeslotRelationFilter = {
  is?: InputMaybe<TimeslotWhereInput>;
  isNot?: InputMaybe<TimeslotWhereInput>;
};

export type TimeslotScalarWhereInput = {
  AND?: InputMaybe<Array<TimeslotScalarWhereInput>>;
  NOT?: InputMaybe<Array<TimeslotScalarWhereInput>>;
  OR?: InputMaybe<Array<TimeslotScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  endAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  ownerId?: InputMaybe<IntFilter>;
  startAt?: InputMaybe<DateTimeFilter>;
  timetableId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TimeslotUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  endAt?: InputMaybe<Scalars['DateTime']['input']>;
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimeslotUpdateManyWithWhereWithoutOwnerInput = {
  data: TimeslotUpdateManyMutationInput;
  where: TimeslotScalarWhereInput;
};

export type TimeslotUpdateManyWithWhereWithoutTimetableInput = {
  data: TimeslotUpdateManyMutationInput;
  where: TimeslotScalarWhereInput;
};

export type TimeslotUpdateManyWithoutOwnerNestedInput = {
  connect?: InputMaybe<Array<TimeslotWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TimeslotCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<TimeslotCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<TimeslotCreateManyOwnerInputEnvelope>;
  delete?: InputMaybe<Array<TimeslotWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TimeslotScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TimeslotWhereUniqueInput>>;
  set?: InputMaybe<Array<TimeslotWhereUniqueInput>>;
  update?: InputMaybe<Array<TimeslotUpdateWithWhereUniqueWithoutOwnerInput>>;
  updateMany?: InputMaybe<Array<TimeslotUpdateManyWithWhereWithoutOwnerInput>>;
  upsert?: InputMaybe<Array<TimeslotUpsertWithWhereUniqueWithoutOwnerInput>>;
};

export type TimeslotUpdateManyWithoutTimetableNestedInput = {
  connect?: InputMaybe<Array<TimeslotWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TimeslotCreateOrConnectWithoutTimetableInput>>;
  create?: InputMaybe<Array<TimeslotCreateWithoutTimetableInput>>;
  createMany?: InputMaybe<TimeslotCreateManyTimetableInputEnvelope>;
  delete?: InputMaybe<Array<TimeslotWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TimeslotScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TimeslotWhereUniqueInput>>;
  set?: InputMaybe<Array<TimeslotWhereUniqueInput>>;
  update?: InputMaybe<Array<TimeslotUpdateWithWhereUniqueWithoutTimetableInput>>;
  updateMany?: InputMaybe<Array<TimeslotUpdateManyWithWhereWithoutTimetableInput>>;
  upsert?: InputMaybe<Array<TimeslotUpsertWithWhereUniqueWithoutTimetableInput>>;
};

export type TimeslotUpdateOneRequiredWithoutAppointmentsNestedInput = {
  connect?: InputMaybe<TimeslotWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TimeslotCreateOrConnectWithoutAppointmentsInput>;
  create?: InputMaybe<TimeslotCreateWithoutAppointmentsInput>;
  update?: InputMaybe<TimeslotUpdateToOneWithWhereWithoutAppointmentsInput>;
  upsert?: InputMaybe<TimeslotUpsertWithoutAppointmentsInput>;
};

export type TimeslotUpdateToOneWithWhereWithoutAppointmentsInput = {
  data: TimeslotUpdateWithoutAppointmentsInput;
  where?: InputMaybe<TimeslotWhereInput>;
};

export type TimeslotUpdateWithWhereUniqueWithoutOwnerInput = {
  data: TimeslotUpdateWithoutOwnerInput;
  where: TimeslotWhereUniqueInput;
};

export type TimeslotUpdateWithWhereUniqueWithoutTimetableInput = {
  data: TimeslotUpdateWithoutTimetableInput;
  where: TimeslotWhereUniqueInput;
};

export type TimeslotUpdateWithoutAppointmentsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  endAt?: InputMaybe<Scalars['DateTime']['input']>;
  owner?: InputMaybe<ProfileUpdateOneRequiredWithoutTimeslotsNestedInput>;
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
  timetable?: InputMaybe<TimetableUpdateOneRequiredWithoutTimeslotsNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimeslotUpdateWithoutOwnerInput = {
  appointments?: InputMaybe<AppointmentUpdateManyWithoutTimeslotNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  endAt?: InputMaybe<Scalars['DateTime']['input']>;
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
  timetable?: InputMaybe<TimetableUpdateOneRequiredWithoutTimeslotsNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimeslotUpdateWithoutTimetableInput = {
  appointments?: InputMaybe<AppointmentUpdateManyWithoutTimeslotNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  endAt?: InputMaybe<Scalars['DateTime']['input']>;
  owner?: InputMaybe<ProfileUpdateOneRequiredWithoutTimeslotsNestedInput>;
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimeslotUpsertWithWhereUniqueWithoutOwnerInput = {
  create: TimeslotCreateWithoutOwnerInput;
  update: TimeslotUpdateWithoutOwnerInput;
  where: TimeslotWhereUniqueInput;
};

export type TimeslotUpsertWithWhereUniqueWithoutTimetableInput = {
  create: TimeslotCreateWithoutTimetableInput;
  update: TimeslotUpdateWithoutTimetableInput;
  where: TimeslotWhereUniqueInput;
};

export type TimeslotUpsertWithoutAppointmentsInput = {
  create: TimeslotCreateWithoutAppointmentsInput;
  update: TimeslotUpdateWithoutAppointmentsInput;
  where?: InputMaybe<TimeslotWhereInput>;
};

export type TimeslotWhereInput = {
  AND?: InputMaybe<Array<TimeslotWhereInput>>;
  NOT?: InputMaybe<Array<TimeslotWhereInput>>;
  OR?: InputMaybe<Array<TimeslotWhereInput>>;
  appointments?: InputMaybe<AppointmentListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  endAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  owner?: InputMaybe<ProfileRelationFilter>;
  ownerId?: InputMaybe<IntFilter>;
  startAt?: InputMaybe<DateTimeFilter>;
  timetable?: InputMaybe<TimetableRelationFilter>;
  timetableId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TimeslotWhereUniqueInput = {
  AND?: InputMaybe<Array<TimeslotWhereInput>>;
  NOT?: InputMaybe<Array<TimeslotWhereInput>>;
  OR?: InputMaybe<Array<TimeslotWhereInput>>;
  appointments?: InputMaybe<AppointmentListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  endAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  owner?: InputMaybe<ProfileRelationFilter>;
  ownerId?: InputMaybe<IntFilter>;
  startAt?: InputMaybe<DateTimeFilter>;
  timetable?: InputMaybe<TimetableRelationFilter>;
  timetableId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Timetable = {
  __typename?: 'Timetable';
  _count: TimetableCount;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  owner: Profile;
  ownerAvatarUrl?: Maybe<Scalars['String']['output']>;
  ownerId: Scalars['Int']['output'];
  ownerName?: Maybe<Scalars['String']['output']>;
  timeslots?: Maybe<Array<Timeslot>>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TimetableCount = {
  __typename?: 'TimetableCount';
  timeslots: Scalars['Int']['output'];
};

export type TimetableCreateManyOwnerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimetableCreateManyOwnerInputEnvelope = {
  data: Array<TimetableCreateManyOwnerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TimetableCreateNestedManyWithoutOwnerInput = {
  connect?: InputMaybe<Array<TimetableWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TimetableCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<TimetableCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<TimetableCreateManyOwnerInputEnvelope>;
};

export type TimetableCreateNestedOneWithoutTimeslotsInput = {
  connect?: InputMaybe<TimetableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TimetableCreateOrConnectWithoutTimeslotsInput>;
  create?: InputMaybe<TimetableCreateWithoutTimeslotsInput>;
};

export type TimetableCreateOrConnectWithoutOwnerInput = {
  create: TimetableCreateWithoutOwnerInput;
  where: TimetableWhereUniqueInput;
};

export type TimetableCreateOrConnectWithoutTimeslotsInput = {
  create: TimetableCreateWithoutTimeslotsInput;
  where: TimetableWhereUniqueInput;
};

export type TimetableCreateWithoutOwnerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  timeslots?: InputMaybe<TimeslotCreateNestedManyWithoutTimetableInput>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimetableCreateWithoutTimeslotsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  owner: ProfileCreateNestedOneWithoutTimetableInput;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimetableListRelationFilter = {
  every?: InputMaybe<TimetableWhereInput>;
  none?: InputMaybe<TimetableWhereInput>;
  some?: InputMaybe<TimetableWhereInput>;
};

export type TimetableRelationFilter = {
  is?: InputMaybe<TimetableWhereInput>;
  isNot?: InputMaybe<TimetableWhereInput>;
};

export type TimetableScalarWhereInput = {
  AND?: InputMaybe<Array<TimetableScalarWhereInput>>;
  NOT?: InputMaybe<Array<TimetableScalarWhereInput>>;
  OR?: InputMaybe<Array<TimetableScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  ownerId?: InputMaybe<IntFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TimetableUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimetableUpdateManyWithWhereWithoutOwnerInput = {
  data: TimetableUpdateManyMutationInput;
  where: TimetableScalarWhereInput;
};

export type TimetableUpdateManyWithoutOwnerNestedInput = {
  connect?: InputMaybe<Array<TimetableWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TimetableCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<TimetableCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<TimetableCreateManyOwnerInputEnvelope>;
  delete?: InputMaybe<Array<TimetableWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TimetableScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TimetableWhereUniqueInput>>;
  set?: InputMaybe<Array<TimetableWhereUniqueInput>>;
  update?: InputMaybe<Array<TimetableUpdateWithWhereUniqueWithoutOwnerInput>>;
  updateMany?: InputMaybe<Array<TimetableUpdateManyWithWhereWithoutOwnerInput>>;
  upsert?: InputMaybe<Array<TimetableUpsertWithWhereUniqueWithoutOwnerInput>>;
};

export type TimetableUpdateOneRequiredWithoutTimeslotsNestedInput = {
  connect?: InputMaybe<TimetableWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TimetableCreateOrConnectWithoutTimeslotsInput>;
  create?: InputMaybe<TimetableCreateWithoutTimeslotsInput>;
  update?: InputMaybe<TimetableUpdateToOneWithWhereWithoutTimeslotsInput>;
  upsert?: InputMaybe<TimetableUpsertWithoutTimeslotsInput>;
};

export type TimetableUpdateToOneWithWhereWithoutTimeslotsInput = {
  data: TimetableUpdateWithoutTimeslotsInput;
  where?: InputMaybe<TimetableWhereInput>;
};

export type TimetableUpdateWithWhereUniqueWithoutOwnerInput = {
  data: TimetableUpdateWithoutOwnerInput;
  where: TimetableWhereUniqueInput;
};

export type TimetableUpdateWithoutOwnerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  timeslots?: InputMaybe<TimeslotUpdateManyWithoutTimetableNestedInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimetableUpdateWithoutTimeslotsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<ProfileUpdateOneRequiredWithoutTimetableNestedInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TimetableUpsertWithWhereUniqueWithoutOwnerInput = {
  create: TimetableCreateWithoutOwnerInput;
  update: TimetableUpdateWithoutOwnerInput;
  where: TimetableWhereUniqueInput;
};

export type TimetableUpsertWithoutTimeslotsInput = {
  create: TimetableCreateWithoutTimeslotsInput;
  update: TimetableUpdateWithoutTimeslotsInput;
  where?: InputMaybe<TimetableWhereInput>;
};

export type TimetableWhereInput = {
  AND?: InputMaybe<Array<TimetableWhereInput>>;
  NOT?: InputMaybe<Array<TimetableWhereInput>>;
  OR?: InputMaybe<Array<TimetableWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  owner?: InputMaybe<ProfileRelationFilter>;
  ownerId?: InputMaybe<IntFilter>;
  timeslots?: InputMaybe<TimeslotListRelationFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TimetableWhereUniqueInput = {
  AND?: InputMaybe<Array<TimetableWhereInput>>;
  NOT?: InputMaybe<Array<TimetableWhereInput>>;
  OR?: InputMaybe<Array<TimetableWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  owner?: InputMaybe<ProfileRelationFilter>;
  ownerId?: InputMaybe<IntFilter>;
  timeslots?: InputMaybe<TimeslotListRelationFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UpdateAppointmentInput = {
  appointmentId: Scalars['Float']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum Weekday {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type WorkingDayInput = {
  day: Weekday;
  timeRange: TimeRangeInput;
};

export type AddGoogleEventMutationVariables = Exact<{
  event: CalendarEventInput;
}>;


export type AddGoogleEventMutation = { __typename?: 'Mutation', addCalendarEvent: { __typename?: 'CalendarEvent', id: string, summary: string, location: string, description: string, start: { __typename?: 'EventDateTime', dateTime: string, timeZone: string }, end: { __typename?: 'EventDateTime', dateTime: string, timeZone: string }, attendees?: Array<{ __typename?: 'EventAttendee', email: string }> | null } };

export type AddNotificationJobMutationVariables = Exact<{
  addNotificationsJobInput: AddNotificationJobInput;
}>;


export type AddNotificationJobMutation = { __typename?: 'Mutation', addNotificationsToAppointment: Array<{ __typename?: 'Notification', id: number, appointmentId: number, jobId: number, recipientId: number }> };

export type ApproveAppointmentMutationVariables = Exact<{
  appointmentId: Scalars['Float']['input'];
}>;


export type ApproveAppointmentMutation = { __typename?: 'Mutation', approveAppointment: { __typename?: 'Appointment', id: number, comment?: string | null, ownerId: number, clientId: number, status: AppointmentStatus, notifications?: Array<{ __typename?: 'Notification', id: number, appointmentId: number, jobId: number, recipientId: number }> | null, timeslot: { __typename?: 'Timeslot', startAt: any } } };

export type BookAppointmentMutationVariables = Exact<{
  createAppointmentInput: CreateAppointmentInput;
}>;


export type BookAppointmentMutation = { __typename?: 'Mutation', bookAppointment: { __typename?: 'Appointment', id: number, comment?: string | null, status: AppointmentStatus, ownerId: number, clientId: number, timeslotId: number, notifications?: Array<{ __typename?: 'Notification', id: number, appointmentId: number, jobId: number, recipientId: number }> | null } };

export type CancelAppointmentMutationVariables = Exact<{
  appointmentId: Scalars['Float']['input'];
}>;


export type CancelAppointmentMutation = { __typename?: 'Mutation', cancelAppointment: { __typename?: 'Appointment', id: number, comment?: string | null, ownerId: number, clientId: number, status: AppointmentStatus, notifications?: Array<{ __typename?: 'Notification', id: number, appointmentId: number, jobId: number, recipientId: number }> | null } };

export type CreatePreviewTimetableMutationVariables = Exact<{
  createTimetableInput: CreateTimetableInput;
}>;


export type CreatePreviewTimetableMutation = { __typename?: 'Mutation', createPreviewTimetable: { __typename?: 'Timetable', title: string, description?: string | null, ownerId: number, timeslots?: Array<{ __typename?: 'Timeslot', id: number, ownerId: number, startAt: any, endAt: any }> | null } };

export type CreateTimeslotsForWorkingDayMutationVariables = Exact<{
  customDateInput: CustomDateInput;
}>;


export type CreateTimeslotsForWorkingDayMutation = { __typename?: 'Mutation', createTimeslotsForWorkingDay: Array<{ __typename?: 'Timeslot', id: number, startAt: any, endAt: any }> };

export type CreateTimetableMutationVariables = Exact<{
  createTimetableInput: CreateTimetableInput;
}>;


export type CreateTimetableMutation = { __typename?: 'Mutation', createTimetable: { __typename?: 'Timetable', id: number, title: string, description?: string | null, timeslots?: Array<{ __typename?: 'Timeslot', id: number, startAt: any, endAt: any }> | null } };

export type DeleteTimeslotMutationVariables = Exact<{
  timeslotId: Scalars['Float']['input'];
}>;


export type DeleteTimeslotMutation = { __typename?: 'Mutation', deleteTimeslot: boolean };

export type DeleteTimetableMutationVariables = Exact<{
  timetableId: Scalars['Float']['input'];
}>;


export type DeleteTimetableMutation = { __typename?: 'Mutation', deleteTimetable: { __typename?: 'Timetable', id: number } };

export type DisableNotificationsMutationVariables = Exact<{
  appointmentId: Scalars['Float']['input'];
}>;


export type DisableNotificationsMutation = { __typename?: 'Mutation', disableNotifications: number };

export type GetAppointmentQueryVariables = Exact<{
  appointmentId: Scalars['Float']['input'];
}>;


export type GetAppointmentQuery = { __typename?: 'Query', getAppointment: { __typename?: 'Appointment', id: number, createdAt: any, updatedAt: any, comment?: string | null, ownerId: number, clientId: number, status: AppointmentStatus, timeslotId: number, notifications?: Array<{ __typename?: 'Notification', id: number, appointmentId: number, jobId: number, recipientId: number }> | null, timeslot: { __typename?: 'Timeslot', id: number, createdAt: any, updatedAt: any, startAt: any, endAt: any, ownerId: number, timetableId: number, timetable: { __typename?: 'Timetable', title: string, description?: string | null } }, client: { __typename?: 'Profile', id: number, name?: string | null, avatarUrl?: string | null } } };

export type GetAppointmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAppointmentsQuery = { __typename?: 'Query', getAppointments: Array<{ __typename?: 'Appointment', id: number, comment?: string | null, ownerId: number, clientId: number, status: AppointmentStatus, timeslotId: number, createdAt: any, updatedAt: any, notifications?: Array<{ __typename?: 'Notification', id: number, appointmentId: number, jobId: number, recipientId: number }> | null, timeslot: { __typename?: 'Timeslot', id: number, createdAt: any, updatedAt: any, startAt: any, endAt: any, ownerId: number, timetableId: number, timetable: { __typename?: 'Timetable', title: string, description?: string | null } }, client: { __typename?: 'Profile', id: number, name?: string | null, avatarUrl?: string | null }, owner: { __typename?: 'Profile', id: number, name?: string | null, avatarUrl?: string | null } }> };

export type GetTimetableQueryVariables = Exact<{
  ownerId: Scalars['Float']['input'];
}>;


export type GetTimetableQuery = { __typename?: 'Query', getTimetable: { __typename?: 'Timetable', id: number, title: string, description?: string | null, ownerId: number, ownerAvatarUrl?: string | null, ownerName?: string | null, timeslots?: Array<{ __typename?: 'Timeslot', id: number, startAt: any, endAt: any, createdAt: any, updatedAt: any, ownerId: number, appointments?: Array<{ __typename?: 'Appointment', id: number, status: AppointmentStatus, timeslotId: number }> | null }> | null } };

export type HasTimetableQueryVariables = Exact<{ [key: string]: never; }>;


export type HasTimetableQuery = { __typename?: 'Query', hasTimetable?: { __typename?: 'Timetable', id: number } | null };

export type GenerateUrlGoogleQueryVariables = Exact<{
  state?: InputMaybe<Scalars['String']['input']>;
}>;


export type GenerateUrlGoogleQuery = { __typename?: 'Query', generateAuthUrl: string };

export type LoginWithGoogleMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;


export type LoginWithGoogleMutation = { __typename?: 'Mutation', loginWithGoogle: { __typename?: 'AuthResponse', token: string, account: { __typename?: 'Account', id: number, telegramId: string } } };

export type LoginViaTelegramMutationVariables = Exact<{
  initLoadData: Scalars['String']['input'];
}>;


export type LoginViaTelegramMutation = { __typename?: 'Mutation', loginViaTelegram: { __typename?: 'AuthResponse', token: string, account: { __typename?: 'Account', id: number, email: string, telegramId: string, profile?: { __typename?: 'Profile', id: number, status: AccountStatus, avatarUrl?: string | null, bio?: string | null, name?: string | null } | null } } };

export type UpdateAppointmentMutationVariables = Exact<{
  updateAppointmentInput: UpdateAppointmentInput;
}>;


export type UpdateAppointmentMutation = { __typename?: 'Mutation', updateAppointment: { __typename?: 'Appointment', id: number, comment?: string | null, status: AppointmentStatus, ownerId: number, clientId: number, timeslotId: number, notifications?: Array<{ __typename?: 'Notification', id: number, appointmentId: number, jobId: number, recipientId: number }> | null } };


export const AddGoogleEventDocument = gql`
    mutation addGoogleEvent($event: CalendarEventInput!) {
  addCalendarEvent(event: $event) {
    id
    summary
    location
    description
    start {
      dateTime
      timeZone
    }
    end {
      dateTime
      timeZone
    }
    attendees {
      email
    }
  }
}
    `;
export type AddGoogleEventMutationFn = Apollo.MutationFunction<AddGoogleEventMutation, AddGoogleEventMutationVariables>;

/**
 * __useAddGoogleEventMutation__
 *
 * To run a mutation, you first call `useAddGoogleEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGoogleEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGoogleEventMutation, { data, loading, error }] = useAddGoogleEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useAddGoogleEventMutation(baseOptions?: Apollo.MutationHookOptions<AddGoogleEventMutation, AddGoogleEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddGoogleEventMutation, AddGoogleEventMutationVariables>(AddGoogleEventDocument, options);
      }
export type AddGoogleEventMutationHookResult = ReturnType<typeof useAddGoogleEventMutation>;
export type AddGoogleEventMutationResult = Apollo.MutationResult<AddGoogleEventMutation>;
export type AddGoogleEventMutationOptions = Apollo.BaseMutationOptions<AddGoogleEventMutation, AddGoogleEventMutationVariables>;
export const AddNotificationJobDocument = gql`
    mutation AddNotificationJob($addNotificationsJobInput: AddNotificationJobInput!) {
  addNotificationsToAppointment(
    addNotificationsJobInput: $addNotificationsJobInput
  ) {
    id
    appointmentId
    jobId
    recipientId
  }
}
    `;
export type AddNotificationJobMutationFn = Apollo.MutationFunction<AddNotificationJobMutation, AddNotificationJobMutationVariables>;

/**
 * __useAddNotificationJobMutation__
 *
 * To run a mutation, you first call `useAddNotificationJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNotificationJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNotificationJobMutation, { data, loading, error }] = useAddNotificationJobMutation({
 *   variables: {
 *      addNotificationsJobInput: // value for 'addNotificationsJobInput'
 *   },
 * });
 */
export function useAddNotificationJobMutation(baseOptions?: Apollo.MutationHookOptions<AddNotificationJobMutation, AddNotificationJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNotificationJobMutation, AddNotificationJobMutationVariables>(AddNotificationJobDocument, options);
      }
export type AddNotificationJobMutationHookResult = ReturnType<typeof useAddNotificationJobMutation>;
export type AddNotificationJobMutationResult = Apollo.MutationResult<AddNotificationJobMutation>;
export type AddNotificationJobMutationOptions = Apollo.BaseMutationOptions<AddNotificationJobMutation, AddNotificationJobMutationVariables>;
export const ApproveAppointmentDocument = gql`
    mutation ApproveAppointment($appointmentId: Float!) {
  approveAppointment(appointmentId: $appointmentId) {
    id
    comment
    ownerId
    clientId
    notifications {
      id
      appointmentId
      jobId
      recipientId
    }
    status
    timeslot {
      startAt
    }
  }
}
    `;
export type ApproveAppointmentMutationFn = Apollo.MutationFunction<ApproveAppointmentMutation, ApproveAppointmentMutationVariables>;

/**
 * __useApproveAppointmentMutation__
 *
 * To run a mutation, you first call `useApproveAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveAppointmentMutation, { data, loading, error }] = useApproveAppointmentMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useApproveAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<ApproveAppointmentMutation, ApproveAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveAppointmentMutation, ApproveAppointmentMutationVariables>(ApproveAppointmentDocument, options);
      }
export type ApproveAppointmentMutationHookResult = ReturnType<typeof useApproveAppointmentMutation>;
export type ApproveAppointmentMutationResult = Apollo.MutationResult<ApproveAppointmentMutation>;
export type ApproveAppointmentMutationOptions = Apollo.BaseMutationOptions<ApproveAppointmentMutation, ApproveAppointmentMutationVariables>;
export const BookAppointmentDocument = gql`
    mutation BookAppointment($createAppointmentInput: CreateAppointmentInput!) {
  bookAppointment(createAppointmentInput: $createAppointmentInput) {
    id
    comment
    status
    ownerId
    clientId
    timeslotId
    notifications {
      id
      appointmentId
      jobId
      recipientId
    }
  }
}
    `;
export type BookAppointmentMutationFn = Apollo.MutationFunction<BookAppointmentMutation, BookAppointmentMutationVariables>;

/**
 * __useBookAppointmentMutation__
 *
 * To run a mutation, you first call `useBookAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookAppointmentMutation, { data, loading, error }] = useBookAppointmentMutation({
 *   variables: {
 *      createAppointmentInput: // value for 'createAppointmentInput'
 *   },
 * });
 */
export function useBookAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<BookAppointmentMutation, BookAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BookAppointmentMutation, BookAppointmentMutationVariables>(BookAppointmentDocument, options);
      }
export type BookAppointmentMutationHookResult = ReturnType<typeof useBookAppointmentMutation>;
export type BookAppointmentMutationResult = Apollo.MutationResult<BookAppointmentMutation>;
export type BookAppointmentMutationOptions = Apollo.BaseMutationOptions<BookAppointmentMutation, BookAppointmentMutationVariables>;
export const CancelAppointmentDocument = gql`
    mutation CancelAppointment($appointmentId: Float!) {
  cancelAppointment(appointmentId: $appointmentId) {
    id
    comment
    ownerId
    clientId
    notifications {
      id
      appointmentId
      jobId
      recipientId
    }
    status
  }
}
    `;
export type CancelAppointmentMutationFn = Apollo.MutationFunction<CancelAppointmentMutation, CancelAppointmentMutationVariables>;

/**
 * __useCancelAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAppointmentMutation, { data, loading, error }] = useCancelAppointmentMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useCancelAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CancelAppointmentMutation, CancelAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelAppointmentMutation, CancelAppointmentMutationVariables>(CancelAppointmentDocument, options);
      }
export type CancelAppointmentMutationHookResult = ReturnType<typeof useCancelAppointmentMutation>;
export type CancelAppointmentMutationResult = Apollo.MutationResult<CancelAppointmentMutation>;
export type CancelAppointmentMutationOptions = Apollo.BaseMutationOptions<CancelAppointmentMutation, CancelAppointmentMutationVariables>;
export const CreatePreviewTimetableDocument = gql`
    mutation CreatePreviewTimetable($createTimetableInput: CreateTimetableInput!) {
  createPreviewTimetable(data: $createTimetableInput) {
    title
    description
    ownerId
    timeslots {
      id
      ownerId
      startAt
      endAt
    }
  }
}
    `;
export type CreatePreviewTimetableMutationFn = Apollo.MutationFunction<CreatePreviewTimetableMutation, CreatePreviewTimetableMutationVariables>;

/**
 * __useCreatePreviewTimetableMutation__
 *
 * To run a mutation, you first call `useCreatePreviewTimetableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePreviewTimetableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPreviewTimetableMutation, { data, loading, error }] = useCreatePreviewTimetableMutation({
 *   variables: {
 *      createTimetableInput: // value for 'createTimetableInput'
 *   },
 * });
 */
export function useCreatePreviewTimetableMutation(baseOptions?: Apollo.MutationHookOptions<CreatePreviewTimetableMutation, CreatePreviewTimetableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePreviewTimetableMutation, CreatePreviewTimetableMutationVariables>(CreatePreviewTimetableDocument, options);
      }
export type CreatePreviewTimetableMutationHookResult = ReturnType<typeof useCreatePreviewTimetableMutation>;
export type CreatePreviewTimetableMutationResult = Apollo.MutationResult<CreatePreviewTimetableMutation>;
export type CreatePreviewTimetableMutationOptions = Apollo.BaseMutationOptions<CreatePreviewTimetableMutation, CreatePreviewTimetableMutationVariables>;
export const CreateTimeslotsForWorkingDayDocument = gql`
    mutation CreateTimeslotsForWorkingDay($customDateInput: CustomDateInput!) {
  createTimeslotsForWorkingDay(data: $customDateInput) {
    id
    startAt
    endAt
  }
}
    `;
export type CreateTimeslotsForWorkingDayMutationFn = Apollo.MutationFunction<CreateTimeslotsForWorkingDayMutation, CreateTimeslotsForWorkingDayMutationVariables>;

/**
 * __useCreateTimeslotsForWorkingDayMutation__
 *
 * To run a mutation, you first call `useCreateTimeslotsForWorkingDayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeslotsForWorkingDayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeslotsForWorkingDayMutation, { data, loading, error }] = useCreateTimeslotsForWorkingDayMutation({
 *   variables: {
 *      customDateInput: // value for 'customDateInput'
 *   },
 * });
 */
export function useCreateTimeslotsForWorkingDayMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimeslotsForWorkingDayMutation, CreateTimeslotsForWorkingDayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTimeslotsForWorkingDayMutation, CreateTimeslotsForWorkingDayMutationVariables>(CreateTimeslotsForWorkingDayDocument, options);
      }
export type CreateTimeslotsForWorkingDayMutationHookResult = ReturnType<typeof useCreateTimeslotsForWorkingDayMutation>;
export type CreateTimeslotsForWorkingDayMutationResult = Apollo.MutationResult<CreateTimeslotsForWorkingDayMutation>;
export type CreateTimeslotsForWorkingDayMutationOptions = Apollo.BaseMutationOptions<CreateTimeslotsForWorkingDayMutation, CreateTimeslotsForWorkingDayMutationVariables>;
export const CreateTimetableDocument = gql`
    mutation CreateTimetable($createTimetableInput: CreateTimetableInput!) {
  createTimetable(data: $createTimetableInput) {
    id
    title
    description
    timeslots {
      id
      startAt
      endAt
    }
  }
}
    `;
export type CreateTimetableMutationFn = Apollo.MutationFunction<CreateTimetableMutation, CreateTimetableMutationVariables>;

/**
 * __useCreateTimetableMutation__
 *
 * To run a mutation, you first call `useCreateTimetableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimetableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimetableMutation, { data, loading, error }] = useCreateTimetableMutation({
 *   variables: {
 *      createTimetableInput: // value for 'createTimetableInput'
 *   },
 * });
 */
export function useCreateTimetableMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimetableMutation, CreateTimetableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTimetableMutation, CreateTimetableMutationVariables>(CreateTimetableDocument, options);
      }
export type CreateTimetableMutationHookResult = ReturnType<typeof useCreateTimetableMutation>;
export type CreateTimetableMutationResult = Apollo.MutationResult<CreateTimetableMutation>;
export type CreateTimetableMutationOptions = Apollo.BaseMutationOptions<CreateTimetableMutation, CreateTimetableMutationVariables>;
export const DeleteTimeslotDocument = gql`
    mutation DeleteTimeslot($timeslotId: Float!) {
  deleteTimeslot(timeslotId: $timeslotId)
}
    `;
export type DeleteTimeslotMutationFn = Apollo.MutationFunction<DeleteTimeslotMutation, DeleteTimeslotMutationVariables>;

/**
 * __useDeleteTimeslotMutation__
 *
 * To run a mutation, you first call `useDeleteTimeslotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimeslotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimeslotMutation, { data, loading, error }] = useDeleteTimeslotMutation({
 *   variables: {
 *      timeslotId: // value for 'timeslotId'
 *   },
 * });
 */
export function useDeleteTimeslotMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTimeslotMutation, DeleteTimeslotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTimeslotMutation, DeleteTimeslotMutationVariables>(DeleteTimeslotDocument, options);
      }
export type DeleteTimeslotMutationHookResult = ReturnType<typeof useDeleteTimeslotMutation>;
export type DeleteTimeslotMutationResult = Apollo.MutationResult<DeleteTimeslotMutation>;
export type DeleteTimeslotMutationOptions = Apollo.BaseMutationOptions<DeleteTimeslotMutation, DeleteTimeslotMutationVariables>;
export const DeleteTimetableDocument = gql`
    mutation DeleteTimetable($timetableId: Float!) {
  deleteTimetable(timetableId: $timetableId) {
    id
  }
}
    `;
export type DeleteTimetableMutationFn = Apollo.MutationFunction<DeleteTimetableMutation, DeleteTimetableMutationVariables>;

/**
 * __useDeleteTimetableMutation__
 *
 * To run a mutation, you first call `useDeleteTimetableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimetableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimetableMutation, { data, loading, error }] = useDeleteTimetableMutation({
 *   variables: {
 *      timetableId: // value for 'timetableId'
 *   },
 * });
 */
export function useDeleteTimetableMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTimetableMutation, DeleteTimetableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTimetableMutation, DeleteTimetableMutationVariables>(DeleteTimetableDocument, options);
      }
export type DeleteTimetableMutationHookResult = ReturnType<typeof useDeleteTimetableMutation>;
export type DeleteTimetableMutationResult = Apollo.MutationResult<DeleteTimetableMutation>;
export type DeleteTimetableMutationOptions = Apollo.BaseMutationOptions<DeleteTimetableMutation, DeleteTimetableMutationVariables>;
export const DisableNotificationsDocument = gql`
    mutation DisableNotifications($appointmentId: Float!) {
  disableNotifications(appointmentId: $appointmentId)
}
    `;
export type DisableNotificationsMutationFn = Apollo.MutationFunction<DisableNotificationsMutation, DisableNotificationsMutationVariables>;

/**
 * __useDisableNotificationsMutation__
 *
 * To run a mutation, you first call `useDisableNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableNotificationsMutation, { data, loading, error }] = useDisableNotificationsMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useDisableNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<DisableNotificationsMutation, DisableNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableNotificationsMutation, DisableNotificationsMutationVariables>(DisableNotificationsDocument, options);
      }
export type DisableNotificationsMutationHookResult = ReturnType<typeof useDisableNotificationsMutation>;
export type DisableNotificationsMutationResult = Apollo.MutationResult<DisableNotificationsMutation>;
export type DisableNotificationsMutationOptions = Apollo.BaseMutationOptions<DisableNotificationsMutation, DisableNotificationsMutationVariables>;
export const GetAppointmentDocument = gql`
    query GetAppointment($appointmentId: Float!) {
  getAppointment(appointmentId: $appointmentId) {
    id
    createdAt
    updatedAt
    comment
    ownerId
    clientId
    status
    notifications {
      id
      appointmentId
      jobId
      recipientId
    }
    timeslotId
    timeslot {
      id
      createdAt
      updatedAt
      startAt
      endAt
      createdAt
      ownerId
      timetableId
      timetable {
        title
        description
      }
    }
    client {
      id
      name
      avatarUrl
    }
  }
}
    `;

/**
 * __useGetAppointmentQuery__
 *
 * To run a query within a React component, call `useGetAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useGetAppointmentQuery(baseOptions: Apollo.QueryHookOptions<GetAppointmentQuery, GetAppointmentQueryVariables> & ({ variables: GetAppointmentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppointmentQuery, GetAppointmentQueryVariables>(GetAppointmentDocument, options);
      }
export function useGetAppointmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppointmentQuery, GetAppointmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppointmentQuery, GetAppointmentQueryVariables>(GetAppointmentDocument, options);
        }
export function useGetAppointmentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAppointmentQuery, GetAppointmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAppointmentQuery, GetAppointmentQueryVariables>(GetAppointmentDocument, options);
        }
export type GetAppointmentQueryHookResult = ReturnType<typeof useGetAppointmentQuery>;
export type GetAppointmentLazyQueryHookResult = ReturnType<typeof useGetAppointmentLazyQuery>;
export type GetAppointmentSuspenseQueryHookResult = ReturnType<typeof useGetAppointmentSuspenseQuery>;
export type GetAppointmentQueryResult = Apollo.QueryResult<GetAppointmentQuery, GetAppointmentQueryVariables>;
export const GetAppointmentsDocument = gql`
    query GetAppointments {
  getAppointments {
    id
    comment
    ownerId
    clientId
    status
    timeslotId
    createdAt
    updatedAt
    notifications {
      id
      appointmentId
      jobId
      recipientId
    }
    timeslot {
      id
      createdAt
      updatedAt
      startAt
      endAt
      ownerId
      timetableId
      timetable {
        title
        description
      }
    }
    client {
      id
      name
      avatarUrl
    }
    owner {
      id
      name
      avatarUrl
    }
  }
}
    `;

/**
 * __useGetAppointmentsQuery__
 *
 * To run a query within a React component, call `useGetAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetAppointmentsQuery, GetAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppointmentsQuery, GetAppointmentsQueryVariables>(GetAppointmentsDocument, options);
      }
export function useGetAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppointmentsQuery, GetAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppointmentsQuery, GetAppointmentsQueryVariables>(GetAppointmentsDocument, options);
        }
export function useGetAppointmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAppointmentsQuery, GetAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAppointmentsQuery, GetAppointmentsQueryVariables>(GetAppointmentsDocument, options);
        }
export type GetAppointmentsQueryHookResult = ReturnType<typeof useGetAppointmentsQuery>;
export type GetAppointmentsLazyQueryHookResult = ReturnType<typeof useGetAppointmentsLazyQuery>;
export type GetAppointmentsSuspenseQueryHookResult = ReturnType<typeof useGetAppointmentsSuspenseQuery>;
export type GetAppointmentsQueryResult = Apollo.QueryResult<GetAppointmentsQuery, GetAppointmentsQueryVariables>;
export const GetTimetableDocument = gql`
    query GetTimetable($ownerId: Float!) {
  getTimetable(ownerId: $ownerId) {
    id
    title
    description
    ownerId
    ownerAvatarUrl
    ownerName
    timeslots {
      id
      startAt
      endAt
      createdAt
      updatedAt
      ownerId
      appointments {
        id
        status
        timeslotId
      }
    }
  }
}
    `;

/**
 * __useGetTimetableQuery__
 *
 * To run a query within a React component, call `useGetTimetableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimetableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimetableQuery({
 *   variables: {
 *      ownerId: // value for 'ownerId'
 *   },
 * });
 */
export function useGetTimetableQuery(baseOptions: Apollo.QueryHookOptions<GetTimetableQuery, GetTimetableQueryVariables> & ({ variables: GetTimetableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTimetableQuery, GetTimetableQueryVariables>(GetTimetableDocument, options);
      }
export function useGetTimetableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTimetableQuery, GetTimetableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTimetableQuery, GetTimetableQueryVariables>(GetTimetableDocument, options);
        }
export function useGetTimetableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTimetableQuery, GetTimetableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTimetableQuery, GetTimetableQueryVariables>(GetTimetableDocument, options);
        }
export type GetTimetableQueryHookResult = ReturnType<typeof useGetTimetableQuery>;
export type GetTimetableLazyQueryHookResult = ReturnType<typeof useGetTimetableLazyQuery>;
export type GetTimetableSuspenseQueryHookResult = ReturnType<typeof useGetTimetableSuspenseQuery>;
export type GetTimetableQueryResult = Apollo.QueryResult<GetTimetableQuery, GetTimetableQueryVariables>;
export const HasTimetableDocument = gql`
    query HasTimetable {
  hasTimetable {
    id
  }
}
    `;

/**
 * __useHasTimetableQuery__
 *
 * To run a query within a React component, call `useHasTimetableQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasTimetableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasTimetableQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasTimetableQuery(baseOptions?: Apollo.QueryHookOptions<HasTimetableQuery, HasTimetableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HasTimetableQuery, HasTimetableQueryVariables>(HasTimetableDocument, options);
      }
export function useHasTimetableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasTimetableQuery, HasTimetableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HasTimetableQuery, HasTimetableQueryVariables>(HasTimetableDocument, options);
        }
export function useHasTimetableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HasTimetableQuery, HasTimetableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HasTimetableQuery, HasTimetableQueryVariables>(HasTimetableDocument, options);
        }
export type HasTimetableQueryHookResult = ReturnType<typeof useHasTimetableQuery>;
export type HasTimetableLazyQueryHookResult = ReturnType<typeof useHasTimetableLazyQuery>;
export type HasTimetableSuspenseQueryHookResult = ReturnType<typeof useHasTimetableSuspenseQuery>;
export type HasTimetableQueryResult = Apollo.QueryResult<HasTimetableQuery, HasTimetableQueryVariables>;
export const GenerateUrlGoogleDocument = gql`
    query generateUrlGoogle($state: String) {
  generateAuthUrl(state: $state)
}
    `;

/**
 * __useGenerateUrlGoogleQuery__
 *
 * To run a query within a React component, call `useGenerateUrlGoogleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateUrlGoogleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateUrlGoogleQuery({
 *   variables: {
 *      state: // value for 'state'
 *   },
 * });
 */
export function useGenerateUrlGoogleQuery(baseOptions?: Apollo.QueryHookOptions<GenerateUrlGoogleQuery, GenerateUrlGoogleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateUrlGoogleQuery, GenerateUrlGoogleQueryVariables>(GenerateUrlGoogleDocument, options);
      }
export function useGenerateUrlGoogleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateUrlGoogleQuery, GenerateUrlGoogleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateUrlGoogleQuery, GenerateUrlGoogleQueryVariables>(GenerateUrlGoogleDocument, options);
        }
export function useGenerateUrlGoogleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GenerateUrlGoogleQuery, GenerateUrlGoogleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GenerateUrlGoogleQuery, GenerateUrlGoogleQueryVariables>(GenerateUrlGoogleDocument, options);
        }
export type GenerateUrlGoogleQueryHookResult = ReturnType<typeof useGenerateUrlGoogleQuery>;
export type GenerateUrlGoogleLazyQueryHookResult = ReturnType<typeof useGenerateUrlGoogleLazyQuery>;
export type GenerateUrlGoogleSuspenseQueryHookResult = ReturnType<typeof useGenerateUrlGoogleSuspenseQuery>;
export type GenerateUrlGoogleQueryResult = Apollo.QueryResult<GenerateUrlGoogleQuery, GenerateUrlGoogleQueryVariables>;
export const LoginWithGoogleDocument = gql`
    mutation loginWithGoogle($code: String!) {
  loginWithGoogle(code: $code) {
    token
    account {
      id
      telegramId
    }
  }
}
    `;
export type LoginWithGoogleMutationFn = Apollo.MutationFunction<LoginWithGoogleMutation, LoginWithGoogleMutationVariables>;

/**
 * __useLoginWithGoogleMutation__
 *
 * To run a mutation, you first call `useLoginWithGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithGoogleMutation, { data, loading, error }] = useLoginWithGoogleMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useLoginWithGoogleMutation(baseOptions?: Apollo.MutationHookOptions<LoginWithGoogleMutation, LoginWithGoogleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginWithGoogleMutation, LoginWithGoogleMutationVariables>(LoginWithGoogleDocument, options);
      }
export type LoginWithGoogleMutationHookResult = ReturnType<typeof useLoginWithGoogleMutation>;
export type LoginWithGoogleMutationResult = Apollo.MutationResult<LoginWithGoogleMutation>;
export type LoginWithGoogleMutationOptions = Apollo.BaseMutationOptions<LoginWithGoogleMutation, LoginWithGoogleMutationVariables>;
export const LoginViaTelegramDocument = gql`
    mutation LoginViaTelegram($initLoadData: String!) {
  loginViaTelegram(data: {initLoadData: $initLoadData}) {
    account {
      id
      email
      telegramId
      profile {
        id
        status
        avatarUrl
        bio
        name
      }
    }
    token
  }
}
    `;
export type LoginViaTelegramMutationFn = Apollo.MutationFunction<LoginViaTelegramMutation, LoginViaTelegramMutationVariables>;

/**
 * __useLoginViaTelegramMutation__
 *
 * To run a mutation, you first call `useLoginViaTelegramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginViaTelegramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginViaTelegramMutation, { data, loading, error }] = useLoginViaTelegramMutation({
 *   variables: {
 *      initLoadData: // value for 'initLoadData'
 *   },
 * });
 */
export function useLoginViaTelegramMutation(baseOptions?: Apollo.MutationHookOptions<LoginViaTelegramMutation, LoginViaTelegramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginViaTelegramMutation, LoginViaTelegramMutationVariables>(LoginViaTelegramDocument, options);
      }
export type LoginViaTelegramMutationHookResult = ReturnType<typeof useLoginViaTelegramMutation>;
export type LoginViaTelegramMutationResult = Apollo.MutationResult<LoginViaTelegramMutation>;
export type LoginViaTelegramMutationOptions = Apollo.BaseMutationOptions<LoginViaTelegramMutation, LoginViaTelegramMutationVariables>;
export const UpdateAppointmentDocument = gql`
    mutation UpdateAppointment($updateAppointmentInput: UpdateAppointmentInput!) {
  updateAppointment(updateAppointmentInput: $updateAppointmentInput) {
    id
    comment
    status
    ownerId
    clientId
    timeslotId
    notifications {
      id
      appointmentId
      jobId
      recipientId
    }
  }
}
    `;
export type UpdateAppointmentMutationFn = Apollo.MutationFunction<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>;

/**
 * __useUpdateAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentMutation, { data, loading, error }] = useUpdateAppointmentMutation({
 *   variables: {
 *      updateAppointmentInput: // value for 'updateAppointmentInput'
 *   },
 * });
 */
export function useUpdateAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>(UpdateAppointmentDocument, options);
      }
export type UpdateAppointmentMutationHookResult = ReturnType<typeof useUpdateAppointmentMutation>;
export type UpdateAppointmentMutationResult = Apollo.MutationResult<UpdateAppointmentMutation>;
export type UpdateAppointmentMutationOptions = Apollo.BaseMutationOptions<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>;