// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable camelcase */
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import {
  createRootRoute,
  Outlet,
  useLocation,
  useNavigate,
} from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { initBackButton, retrieveLaunchParams } from "@telegram-apps/sdk";
import { ConfigProvider, Flex, theme } from "antd";
import en_US from "antd/locale/en_US";
import ru_RU from "antd/locale/ru_RU";

import { Header } from "../components/header.tsx";
import { useAuthStore } from "../stores/auth.store.ts";

const RootComponent: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isDevelopment = import.meta.env.MODE === "development";
  const [isDarkTheme, setIsDarkTheme] = useState<boolean>(
    window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches,
  );
  const [backButton, cleanupBackButton] = initBackButton();

  const { startParam } = retrieveLaunchParams();

  useEffect(() => {
    if (startParam) {
      navigate({
        to: "/timetable/view",
        search: { ownerId: startParam },
      });
    }
  }, [startParam]);

  useEffect(() => {
    if (backButton) {
      if (location.pathname === "/") {
        backButton.hide();
      } else {
        backButton.show();
      }
    } else {
      console.error("BackButton initialization failed");
    }

    return (): void => {
      cleanupBackButton();
    };
  }, [location.pathname]);

  backButton.on("click", async () => {
    await navigate({
      to: "/",
    });
  });

  const [locale, setLocale] = useState(en_US);

  const authStore = useAuthStore();

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleThemeChange = (event: MediaQueryListEvent): void => {
      setIsDarkTheme(event.matches);
    };
    mediaQuery.addEventListener("change", handleThemeChange);
    return (): void =>
      mediaQuery.removeEventListener("change", handleThemeChange);
  }, []);

  useEffect(() => {
    const language = navigator.language;
    console.log(language);
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (language) {
      case "ru": {
        setLocale(ru_RU);
        break;
      }
      default: {
        setLocale(en_US);
        break;
      }
    }
  }, []);

  return (
    <ApolloProvider client={makeApolloClient(authStore.token)}>
      <ConfigProvider
        theme={{
          algorithm: isDarkTheme ? theme.darkAlgorithm : theme.defaultAlgorithm,
        }}
        locale={locale}
      >
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-TTBHXM277B"
          ></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-TTBHXM277B');
            `}
          </script>
        </Helmet>
        <Header />
        <Flex
          style={{ height: "92vh" }}
          vertical
          justify={"space-between"}
          align={"center"}
        >
          <div style={{ width: "80vw" }}>
            <Outlet />
            {isDevelopment && <TanStackRouterDevtools />}
          </div>
        </Flex>
      </ConfigProvider>
    </ApolloProvider>
  );
};

function makeApolloClient(
  accessToken?: string | undefined,
): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    uri: import.meta.env.VITE_GRAPHQL_API_URL,
    cache: new InMemoryCache(),
    ...(accessToken && {
      headers: {
        authorization: accessToken,
      },
    }),
  });
}

export const Route = createRootRoute({
  component: RootComponent,
});
